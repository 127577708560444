import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "react-dropdown";

import "./screen-time.scss";

import DetailCard from "../../../components/detailCard/detail-card";
import { getLanguageNumber } from "../../../helper/common-helpers/language-helper";
import { useAnalytics } from "../analytics-context";
import AdvancedProgressBar from "../../../components/progress-bar/advanced-progress-bar";
import { useScreenTimeData } from "../../../hooks/use-screen-time-data";
import { ChildToShow } from "../../../interfaces/analytics-interfaces/analytics-interface";
import ScreenTimeHistory from "../../../components/analytics/screen-time/screen-time-history";
import { ShowMoreIconDetails } from "../../../constants/recommendation-constants";
import AnalyticsLoading from "../../../components/analytics/analytics-loading";
import { getTodayMetrics } from "../../../helper/analytics-helpers/summary-helpers";

interface ScreenTimeProps {
  currentChildToShow: ChildToShow | null;
}

const ScreenTime: React.FC<ScreenTimeProps> = (props: ScreenTimeProps) => {
  const { t, i18n } = useTranslation();
  const actualLanguage = i18n.language;
  const languageNumber = getLanguageNumber(actualLanguage);
  const { isLoading, error } = useAnalytics();

  const [todaySessionTime, setTodaySessionTime] = useState<number>(0);
  const [todayVisits, setTodayVisits] = useState<number>(0);

  const DEFAULT_MAX_SESSION_TIME = 60; // 60 minutes by OMS

  const [rowsToShow, setRowsToShow] = useState(30);

  const [dateScreenTimeFilter, setDateScreenTimeFilter] =
    useState<string>("lastWeek");
  const dateScreenTimeFilterStates = ["lastWeek", "lastMonth", "lastYear"];

  const { isProcessingScreenTimeData, screenTimeData } = useScreenTimeData({
    childrenData: props.currentChildToShow,
    actualLanguage: languageNumber,
    dateLimit: dateScreenTimeFilter,
  });

  useEffect(() => {
    const { todaySessionTime, todaySessions } = getTodayMetrics(screenTimeData);
    setTodaySessionTime(todaySessionTime);
    setTodayVisits(todaySessions);
  }, [screenTimeData]);

  const handleDateDropdownOptionChange = (
    selected: number,
    isScreenTime: boolean
  ) => {
    let states: string[];
    if (isScreenTime) {
      states = dateScreenTimeFilterStates;
    }

    const filter = states[selected];
    if (isScreenTime) {
      setDateScreenTimeFilter(filter);
    }
  };

  return (
    <>
      {error ? <p style={{ color: "red" }}>Error: {error.message}</p> : null}

      {isLoading || isProcessingScreenTimeData ? (
        <AnalyticsLoading />
      ) : !screenTimeData || !screenTimeData.dailyDetails ? (
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <p>{t("analyticsPages.screen_time_page.no_data_available")}</p>
        </div>
      ) : (
        <>
          <div className="analytics__stats-content--card">
            <DetailCard
              hasHeader={true}
              headerTitle={t("analyticsPages.screen_time_page.today_time")}
              themeColor="#823d90"
            >
              <AdvancedProgressBar
                styleOverride={{ margin: "12px 0 24px 0" }}
                completed={todaySessionTime}
                barSize="medium"
                enableDynamicColor={true}
                customMaxValue={DEFAULT_MAX_SESSION_TIME}
                infoTopLeft={
                  <p className="summary__screen-time__info-top-left">
                    {todaySessionTime}{" "}
                    {t("analyticsPages.summary_page.minutes")} (
                    {Math.round(
                      ((todaySessionTime || 0) / DEFAULT_MAX_SESSION_TIME) * 100
                    )}
                    %)
                  </p>
                }
                infoBottomLeft={
                  <p>
                    {todayVisits} {t("analyticsPages.summary_page.visits")}
                  </p>
                }
                infoBottomRight={
                  <p>
                    {t("analyticsPages.summary_page.limit")}{" "}
                    {DEFAULT_MAX_SESSION_TIME}{" "}
                    {t("analyticsPages.summary_page.minutes_short")}
                  </p>
                }
              />
            </DetailCard>
          </div>

          <div className="analytics__stats-content--card">
            <DetailCard
              hasHeader={true}
              headerTitle={t("analyticsPages.screen_time_page.average_time")}
              themeColor="#823d90"
              iconRight={
                <>
                  <Dropdown
                    className="screentime__header--dropdown"
                    options={dateScreenTimeFilterStates.map((state) =>
                      t(`analyticsPages.screen_time_page.${state}`)
                    )}
                    value={t(
                      `analyticsPages.screen_time_page.${dateScreenTimeFilter}`
                    )}
                    onChange={(option) => {
                      const selectedValue = option.value;
                      const selectedIdx = dateScreenTimeFilterStates.findIndex(
                        (state) =>
                          t(`analyticsPages.screen_time_page.${state}`) ===
                          selectedValue
                      );
                      handleDateDropdownOptionChange(selectedIdx, true);
                    }}
                  />
                </>
              }
            >
              <AdvancedProgressBar
                styleOverride={{ margin: "12px 0 24px 0" }}
                completed={screenTimeData?.averageTimeInMinutes}
                barSize="medium"
                enableDynamicColor={true}
                customMaxValue={DEFAULT_MAX_SESSION_TIME}
                infoTopLeft={
                  <p className="summary__screen-time__info-top-left">
                    <span className="summary__screen-time__info-top-left--title">
                      {screenTimeData?.averageTimeInMinutes}{" "}
                      {t("analyticsPages.screen_time_page.minutes_per")}{" "}
                      {t("analyticsPages.screen_time_page.day")}{" "}
                      {t("analyticsPages.screen_time_page.in_average")}{" "}
                    </span>
                    <span className="summary__screen-time__info-top-left--subtitle">
                      (
                      {Math.round(
                        ((screenTimeData?.averageTimeInMinutes || 0) /
                          DEFAULT_MAX_SESSION_TIME) *
                          100
                      )}
                      % {t("analyticsPages.screen_time_page.recommended_time")})
                    </span>
                  </p>
                }
                infoBottomLeft={
                  <p>
                    {screenTimeData?.averageSessions}{" "}
                    {t(`analyticsPages.screen_time_page.visits_per`)}{" "}
                    {t("analyticsPages.screen_time_page.day")}{" "}
                    {t("analyticsPages.screen_time_page.in_average")}
                  </p>
                }
              />
            </DetailCard>
          </div>

          <div className="analytics__stats-content--card">
            <DetailCard
              hasHeader={true}
              headerTitle={t("analyticsPages.screen_time_page.session_history")}
              themeColor="#823d90"
            >
              <ScreenTimeHistory
                dailyDetails={screenTimeData?.dailyDetails}
                maxSessionTime={DEFAULT_MAX_SESSION_TIME}
                rowsToShow={rowsToShow}
                extraBottomComponent={
                  <div className="screen-time-history__footer">
                    {rowsToShow <
                      Object.keys(screenTimeData?.dailyDetails || {})
                        .length && (
                      <button onClick={() => setRowsToShow(rowsToShow + 30)}>
                        <img
                          src={ShowMoreIconDetails.src}
                          alt={ShowMoreIconDetails.alt}
                        />
                      </button>
                    )}
                  </div>
                }
              />
            </DetailCard>
          </div>
        </>
      )}
    </>
  );
};

export default ScreenTime;
