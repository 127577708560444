import Cookies from "js-cookie";

export const handleLanguage = (
  langId: string | null,
  changeLanguage: (lang: string) => void
) => {
  if (langId === null) {
    Cookies.remove("lang");
    return;
  }

  Cookies.set("unityLangId", langId, {
    expires: 7,
    secure: true,
  });

  const langMap: Record<string, string> = {
    "0": "en",
    "1": "fr",
    "2": "de",
  };

  const newLang = langMap[langId] || "en";
  changeLanguage(newLang);
};

export const handleChildId = (unityChildId: string | null) => {
  Cookies.set("unityChildId", unityChildId, {
    expires: 1,
    secure: true,
  });
};

export const handleFamilyData = (
  familyData: any,
  currentChildToShow: any,
  setCurrentChildToShow: any,
  changeLanguage: (lang: string) => void
) => {
  if (!familyData || currentChildToShow) return;

  const unityLangId = Cookies.get("unityLangId");
  const unityChildId = Cookies.get("unityChildId");

  if (unityChildId) {
    const childToShow = familyData.childrenData.find(
      (child) => child.childId === unityChildId
    );
    if (childToShow) {
      setCurrentChildToShow(childToShow);
    }
  } else {
    setCurrentChildToShow(familyData.childrenData[0]);
  }

  if (unityLangId) {
    const langMap: Record<string, string> = {
      "0": "en",
      "1": "fr",
      "2": "de",
    };

    const newLang = langMap[unityLangId] || "en";
    changeLanguage(newLang);
  }
};

export const findChildIndex = (
  childId: number,
  children: { childId: number | null; childName: string | null }[]
): number => {
  const index = children.findIndex((child) => child.childId === childId);
  return index !== -1 ? index : 0;
};
