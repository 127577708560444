import React, { ChangeEvent, useState } from "react";
import axios from "axios";

import "./settings-security.scss";

import { useTranslation } from "react-i18next";
import ConfirmationPopup from "../../../components/analytics/general/confirmation-popup";
import { AnalyticsAccountDataType } from "../../../interfaces/analytics-interfaces/analytics-interface";
import URLS from "../../../config/api-urls";
import { removeChildCookie } from "../../../helper/auth-helpers/cookie-helper";

interface SettingsSecurityProps {
  updateSecurity: (key: string, value: string) => void;
  parentEmail: string;
  childrenSettings: {
    ChildId: number;
    ChildName: string;
  }[];
  familyData: AnalyticsAccountDataType | null;
  setFamilyData: (familyData: AnalyticsAccountDataType | null) => void;
}

const SettingsSecurity: React.FC<SettingsSecurityProps> = (
  props: SettingsSecurityProps
) => {
  const { t } = useTranslation();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [selectedChildId, setSelectedChildId] = useState(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    props.updateSecurity(name, value);
  };

  const handleDeleteClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    childId: number
  ) => {
    event.preventDefault();

    const totalChildren = props.familyData
      ? props.familyData.childrenData.length
      : 0;

    if (totalChildren <= 1) {
      // Afficher une popup d'avertissement indiquant qu'on ne peut pas supprimer le seul enfant
      setShowWarningPopup(true); // setShowWarningPopup est un nouvel état pour gérer l'affichage de la popup d'avertissement
    } else {
      setSelectedChildId(childId);
      setShowDeleteConfirm(true);
    }
  };

  const handleClosePopup = () => {
    setShowDeleteConfirm(false);
    setSelectedChildId(null);
  };

  const handleConfirmDelete = (event, childId: number, childToken: string) => {
    event.preventDefault();

    if (childId && childToken) {
      const data = {
        ChildId: childId,
        Token: childToken,
      };

      axios
        .post(URLS.POST_DeleteCHild, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (props.familyData && props.familyData.childrenData) {
            const childIndex = props.familyData.childrenData.findIndex(
              (child) => Number(child.childId) === childId
            );
            if (childIndex !== -1) {
              removeChildCookie(childIndex + 1);

              const updatedChildren = props.familyData.childrenData.filter(
                (child) => Number(child.childId) !== childId
              );
              props.setFamilyData({
                ...props.familyData,
                childrenData: updatedChildren,
                totalChildren: updatedChildren.length,
              });
            }
          }

          handleClosePopup();

          window.location.reload();
        })
        .catch((error) => {
          console.error("Erreur lors de la suppression:", error);
        });
    } else {
      console.error("Données manquantes pour la suppression");
    }
  };

  return (
    <div className="auth__analytics-settings-security">
      <form className="auth__analytics-settings-security__form">
        <div className="auth__analytics-settings-security__child-list">
          <label className="auth__analytics-settings-security__label">
            {t("analyticsPages.settings_page.child_list")}
          </label>
          {props.childrenSettings.map((child, index) => (
            <div
              key={child.ChildId}
              className="auth__analytics-settings-security__child-list--container"
            >
              <input
                type="text"
                value={child.ChildName}
                disabled
                className="auth__analytics-settings-security__input"
              />
              <button
                onClick={(e) => handleDeleteClick(e, child.ChildId)}
                style={
                  props.childrenSettings.length === 1
                    ? {
                        backgroundColor: "grey",
                        cursor: "not-allowed",
                        opacity: 0.25,
                      }
                    : {}
                }
                disabled={props.childrenSettings.length === 1}
              >
                {t("analyticsPages.settings_page.delete")}
              </button>
            </div>
          ))}
          {showDeleteConfirm && selectedChildId && (
            <ConfirmationPopup
              childId={selectedChildId}
              familyData={props.familyData}
              onClose={handleClosePopup}
              onConfirmDelete={handleConfirmDelete}
            />
          )}
        </div>

        <label className="auth__analytics-settings-security__label">
          {t("analyticsPages.settings_page.parent_email")}
          <input
            type="text"
            value={props.parentEmail}
            autoComplete="none"
            disabled
            className="auth__analytics-settings-security__input"
          />
        </label>

        <label className="auth__analytics-settings-security__label">
          {t("analyticsPages.settings_page.parent_password")}
          <input
            type="password"
            name="newPassword"
            autoComplete="new-password"
            className="auth__analytics-settings-security__input"
            onChange={handleInputChange}
          />
        </label>

        <label className="auth__analytics-settings-security__label">
          {t("analyticsPages.settings_page.repeat_parent_password")}
          <input
            type="password"
            name="confirmPassword"
            autoComplete="new-password"
            className="auth__analytics-settings-security__input"
            onChange={handleInputChange}
          />
        </label>
      </form>
    </div>
  );
};

export default SettingsSecurity;
