import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

import "./profile-selector-pc.scss";

import TestAvatar from "../../../resources/images/wytopia-icon-02.png";
import disconnectIcon from "../../../resources/images/icons/sortie_blanc.png";
import checkIcon from "../../../resources/images/icons/check_blanc.png";
import arrowLeftIcon from "../../../resources/images/icons/gauche_blanc.png";
import arrowRightIcon from "../../../resources/images/icons/droite_blanc.png";
import { ProfileSelectorPcProps } from "../../../interfaces/analytics-interfaces/analytics-interface";
import { findChildIndex } from "../../../helper/common-helpers/params-helper";

const ProfileSelectorPc: React.FC<ProfileSelectorPcProps> = (
  props: ProfileSelectorPcProps
) => {
  const initialIndex = props?.familyData?.childrenData
    ? findChildIndex(
        props.currentChildToShow?.childId,
        props.familyData?.childrenData
      )
    : 0;

  const [currentChildIndex, setCurrentChildIndex] = useState(initialIndex);

  const switchChild = (direction: "left" | "right") => {
    if (
      !props.familyData?.childrenData ||
      props.familyData.childrenData.length === 0
    )
      return;

    const children = props.familyData.childrenData;

    let newIndex = currentChildIndex;

    if (direction === "left") {
      newIndex = newIndex === 0 ? children.length - 1 : newIndex - 1;
    } else {
      newIndex = (newIndex + 1) % children.length;
    }

    setCurrentChildIndex(newIndex);
    props.setCurrentChildToShow(children[newIndex]);
    // Cookies.remove("unityChildId");
  };

  useEffect(() => {
    const newIndex = props.familyData?.childrenData
      ? findChildIndex(
          props.currentChildToShow?.childId,
          props.familyData?.childrenData
        )
      : 0;
    setCurrentChildIndex(newIndex);
  }, [props.currentChildToShow, props.familyData]);

  return (
    <div className="profile-selector-pc">
      <div className="top-section">
        <img
          src={arrowLeftIcon}
          onClick={() => switchChild("left")}
          alt="Arrow Left"
          className="arrow-icon"
        />
        <img src={TestAvatar} alt="User Avatar" className="avatar" />
        <img
          src={arrowRightIcon}
          onClick={() => switchChild("right")}
          alt="Arrow Right"
          className="arrow-icon"
        />
      </div>

      <div className="mid-section">
        <div className="profile-username">{props.childName?.toUpperCase()}</div>
      </div>

      <div className="bottom-section">
        <div className="status">
          <img src={checkIcon} alt="Tick Icon" className="tick-icon" />
          <span>Connecté</span>
        </div>
        <button className="logout-btn" onClick={props.handleLogout}>
          <img src={disconnectIcon} alt="Logout Icon" />
        </button>
      </div>
    </div>
  );
};

export default ProfileSelectorPc;
