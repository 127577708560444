import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "./recommendation-popup.scss";
import {
  CloseIconDetails,
  ImposeIconDetails,
  SuggestIconDetails,
  FamilyIconDetails,
} from "../../../constants/recommendation-constants";
import { handleSendRecommendationButtonClick } from "../../../helper/apiHelper/recommendation_api";
import { IconProps } from "../../detailCard/detail-card";
import {
  ChildToShow,
  AnalyticsAccountDataType,
} from "../../../interfaces/analytics-interfaces/analytics-interface";

interface RecommendationPopupProps {
  setIsPopupVisible: (visible: boolean) => void;
  setIsRecommendationSent: (sent: boolean) => void;
  popupData: {
    contentId: number;
    childId: number;
    activityTitle: string;
    activityCategory: string;
    activityCategoryIcon: IconProps;
    themeColor: string;
  } | null;
  currentChildToShow: ChildToShow | null;
  familyData: AnalyticsAccountDataType | null;
}

const RecommendationPopup: React.FC<RecommendationPopupProps> = (
  props: RecommendationPopupProps
) => {
  const { t } = useTranslation();
  const [sendToAllChildren, setSendToAllChildren] = useState(false);

  const handleFamilyButtonClick = () => {
    setSendToAllChildren(!sendToAllChildren);
  };

  const handleRecommendationClick = (imposed: boolean) => {
    const childIds = sendToAllChildren
      ? props.familyData?.childrenData?.map((child) => child.childId) || []
      : [props.currentChildToShow.childId];

    Promise.all(
      childIds.map((childId) =>
        handleSendRecommendationButtonClick(
          props.popupData.contentId,
          childId,
          imposed
        )
      )
    )
      .then(() => {
        props.setIsPopupVisible(false);
        props.setIsRecommendationSent(true);
      })
      .catch((error) => {
        // handle error here
      });
  };

  return (
    <>
      <div className="recommendation-popup">
        <div className="recommendation-popup__background"></div>
        <div className="recommendation-popup__container">
          <button
            className="recommendation-popup__header__close"
            onClick={() => props.setIsPopupVisible(false)}
          >
            <img src={CloseIconDetails.src} alt={CloseIconDetails.alt} />
          </button>
          <div className="recommendation-popup__header">
            <p>
              {t("analyticsPages.recommendation_page.send_recommendation_to")}
              {sendToAllChildren ? (
                <>
                  ({props.familyData?.childrenData?.length || 0}){" "}
                  {props.familyData?.childrenData?.map((child, index) => (
                    <span key={child.childId}>
                      {index > 0 ? ", " : ""}
                      {child.childName}
                    </span>
                  ))}
                </>
              ) : (
                props.currentChildToShow.childName
              )}
            </p>
            <button
              className="recommendation-popup__choices--family"
              onClick={() => handleFamilyButtonClick()}
              style={{
                backgroundColor: sendToAllChildren ? "green" : "grey",
              }}
            >
              <div>
                <img src={FamilyIconDetails.src} alt={FamilyIconDetails.alt} />
              </div>
              <div>
                <p>
                  {t(
                    "analyticsPages.recommendation_page.send_recommendation_to_family"
                  )}
                </p>
              </div>
            </button>
          </div>
          <div className="recommendation-popup__infos">
            <div>
              <img
                src={props.popupData.activityCategoryIcon.src}
                alt={props.popupData.activityCategoryIcon.alt}
              />
            </div>
            <div>
              <div>
                <p
                  className="recommendation-popup__infos--title"
                  style={{ color: `#${props.popupData.themeColor}` }}
                >
                  {props.popupData.activityTitle}
                </p>
              </div>
              <div>
                <p
                  className="recommendation-popup__infos--subtitle"
                  style={{ color: `#${props.popupData.themeColor}` }}
                >
                  {props.popupData.activityCategory}
                </p>
              </div>
            </div>
          </div>
          <div className="recommendation-popup__choices">
            <button
              className="recommendation-popup__choices--suggest"
              onClick={() => handleRecommendationClick(false)}
            >
              <div>
                <img
                  src={SuggestIconDetails.src}
                  alt={SuggestIconDetails.alt}
                />
              </div>
              <div>
                <p>
                  {t(
                    "analyticsPages.recommendation_page.send_recommendation_suggest"
                  )}
                </p>
              </div>
            </button>
            <button
              className="recommendation-popup__choices--impose"
              onClick={() => handleRecommendationClick(true)}
            >
              <div>
                <img src={ImposeIconDetails.src} alt={ImposeIconDetails.alt} />
              </div>
              <div>
                <p>
                  {t(
                    "analyticsPages.recommendation_page.send_recommendation_impose"
                  )}
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecommendationPopup;
