const API_URL = "https://wytopiaapi.azurewebsites.net";
// const API_URL = "http://localhost:7255";

const URLS = {
  POST_NewParent: API_URL + "/api/NewParent",
  POST_UpdateParent: API_URL + "/api/UpdateParent",
  POST_GetParent: API_URL + "/api/GetParent",
  POST_NewChild: API_URL + "/api/NewChild",
  POST_UpdateChild: API_URL + "/api/UpdateChild",
  POST_GetChilds: API_URL + "/api/GetChilds",
  POST_ConnectWeb: API_URL + "/api/ConnectWeb",
  POST_AskResetPasswordParent: API_URL + "/api/ResetPassword",
  POST_AskResetPasswordChild: API_URL + "/api/ResetChildPassword",
  POST_ReinitPasswordParent: API_URL + "/api/ReinitPassword",
  POST_ReinitPasswordChild: API_URL + "/api/ReinitChildPassword",
  POST_ResendParentEmail: API_URL + "/api/ResendParentEmail",
  POST_Skills: API_URL + "/api/GetSkills",
  POST_ChildGameProgresses: API_URL + "/api/GetChildGameProgresses",
  POST_GetChildContentProgresses: API_URL + "/api/GetChildContentProgresses",
  POST_ChildSkillHistory: API_URL + "/api/GetChildSkillHistorical",
  POST_GetSkillsStatistics: API_URL + "/api/GetSkillsStatistics",
  POST_GetContentSkills: API_URL + "/api/GetContentSkills",
  POST_GetContents: API_URL + "/api/GetContents",
  POST_GetContentsFeedbacks: API_URL + "/api/GetContentsFeedbacks",
  POST_NewChildRecommendation: API_URL + "/api/NewChildRecommendation",
  POST_GetChildActivityTimes: API_URL + "/api/GetChildActivityTimes",
  POST_GetChildQuizHistories: API_URL + "/api/GetChildQuizHistories",
  POST_GetChildFeedbacks: API_URL + "/api/GetChildFeedbacks",
  POST_GetContentGameSettings: API_URL + "/api/GetContentGameSettings",
  POST_GetContentStatistics: API_URL + "/api/GetContentStatistics",
  POST_GetChildSessionTime: API_URL + "/api/GetChildSessionTime",
  POST_GetAllChildSessionTime: API_URL + "/api/GetAllChildSessionTime",
  POST_DeleteCHild: API_URL + "/api/DeleteChild",
};

export default URLS;
