import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

import URLS from "../../config/api-urls";

import ResetPassword from "../auth/reinit-password";
import SingleForm from "../../components/forms/single-form/single-form";
import SelectForm from "../../components/forms/select-form/select-form";
import DayCounter from "../../components/forms/day-counter/day-counter";
import "./settings.scss";

interface IFormField {
  labelText: string;
  inputType: string;
  inputValue: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  isRequired: boolean;
  isEditable: boolean;
}

interface ISelectField extends IFormField {
  choices: string[];
}

interface IDayCounterField {
  day: string;
  inputValue: number;
  setDaysCount: React.Dispatch<React.SetStateAction<any>>;
}

interface IChildAccess {
  limited: boolean;
  mondayHour: number;
  tuesdayHour: number;
  wednesdayHour: number;
  thursdayHour: number;
  fridayHour: number;
  saturdayHour: number;
  sundayHour: number;
}

interface IChildNotifications {
  achievements: boolean;
  needs: boolean;
  journeyReport: number;
}

interface IAPIResponse {
  data: {
    object: {
      token: string;
      email: string;
      referentId: string;
      username: string;
    };
    statusMessage: string;
  };
}

interface IChildData {
  userName: string;
  childId: string;
  dateofBirth: string;
  gender: string;
  access: IChildAccess;
  notification: IChildNotifications;
  token: string;
}

interface IParentData {
  email: string;
  children: IChildData[];
}

interface SettingsProps {
  match: any;
}

interface ResponseDataType {
  data?: any; // Remplacez `any` par le type spécifique que vous attendez
  status?: number;
  statusText?: string;
  // Ajoutez d'autres champs selon les données renvoyées par l'API
}
interface IParentFormattedData {
  token: string;
  email: string;
  password: string;
  // Ajoutez d'autres champs si nécessaire
}

interface IChildFormattedData {
  token: string;
  childId: string;
  userName: string;
  dateofBirth: string;
  gender: string;
  access: IChildAccess;
  notification: IChildNotifications;
  // Ajoutez d'autres champs si nécessaire
}


const Settings = () => {
  const { t, i18n } = useTranslation();

  const cookieToken = Cookies.get("tokenSession");
  const cookieUsername = Cookies.get("username");
  const cookieParentEmail = Cookies.get("parentEmail");
  const cookieParentId = Cookies.get("parentID");

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [responseData, setResponseData] = useState<ResponseDataType>({});
  const [isSubmitOK, setIsSubmitOK] = useState(false);

  //regroup all data in one object
  const [parentData, setParentData] = useState<IParentData | null>(null);
  // console.log("parentData", parentData);
  const [childData, setChildData] = useState<IChildData[]>([]);
  // console.log("childData", childData);

  const [parentEmail, setParentEmail] = useState(cookieParentEmail);
  const [parentPassword, setParentPassword] = useState("");
  const [confirmParentPassword, setConfirmParentPassword] = useState("");
  const [childId, setChildId] = useState(cookieUsername);
  const [childName, setChildName] = useState(cookieUsername);
  const [childDateOfBirth, setChildDateOfBirth] = useState("");
  const [childGender, setChildGender] = useState("");
  const [childAccess, setChildAccess] = useState({
    limited: false, // true = limited, false = unlimited
    mondayHour: 2,
    tuesdayHour: 2,
    wednesdayHour: 2,
    thursdayHour: 2,
    fridayHour: 2,
    saturdayHour: 6,
    sundayHour: 6,
  });
  const days = Object.keys(childAccess).filter((key) => key !== "limited");
  const [childNotifications, setChildNotifications] = useState({
    achievements: false,
    needs: false,
    journeyReport: 0,
  });
  const [selectedChild, setSelectedChild] = useState(0);
  const [childToken, setChildToken] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  // Get parent and childrens data
  useEffect(() => {
    axios
      .post(URLS.POST_GetParent, {
        token: cookieToken,
        referentId: cookieParentId, // parentID,
      })
      .then((response) => {
        // console.log("response", response);
        setParentData(response.data.object);
        setChildData(response.data.object.children);
        setParentEmail(response.data.object.email);
        setChildName(response.data.object.children[selectedChild].userName);
        setChildId(response.data.object.children[selectedChild].childId);
        setChildDateOfBirth(
          response.data.object.children[selectedChild].dateofBirth
        );
        setChildGender(response.data.object.children[selectedChild].gender);
        setChildAccess(response.data.object.children[selectedChild].access);
        setChildNotifications(
          response.data.object.children[selectedChild].notification
        );
        setChildToken(response.data.object.children[selectedChild].token);
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }, []);

  const setCookies = () => {
    Cookies.set("connected", responseData.data.object.token, {
      expires: 7,
    });
    Cookies.set("email", responseData.data.object.email, {
      expires: 7,
    });
    Cookies.set("id", responseData.data.object.referentId, {
      expires: 7,
    });
    Cookies.set("username", responseData.data.object.username, {
      expires: 7,
    });
  };

  const formatAndSendUpdate = async (data: any) => {
    if (parentPassword !== confirmParentPassword) {
      setErrorMessage("Les mots de passe ne correspondent pas !");
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const parentFormattedData: IParentFormattedData = {
      token: cookieToken,
      email: parentEmail,
      password: parentPassword,
    };

    const childFormattedData: IChildFormattedData = {
      token: childToken,
      childId: childId,
      userName: childName,
      dateofBirth: childDateOfBirth,
      gender: childGender,
      access: childAccess,
      notification: childNotifications,
    };

    try {
      const parentResponse = await axios.post(
        URLS.POST_UpdateParent,
        parentFormattedData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setResponseData(parentResponse);

      const childResponse = await axios.post(
        URLS.POST_UpdateChild,
        childFormattedData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setResponseData(childResponse);

      setIsLoading(false);
      setIsSubmitOK(true);
      setCookies();
    } catch (error) {
      setResponseData(error);
      setIsLoading(false);
      setIsSubmitOK(false);
    }
  };

  const formDataParent = [
    {
      labelText: "parent_email",
      inputType: "text",
      inputValue: parentEmail,
      setValue: setParentEmail,
      isRequired: true,
      isEditable: true,
    },
    {
      labelText: "parent_password",
      inputType: "password",
      inputValue: parentPassword,
      setValue: setParentPassword,
      isRequired: true,
      isEditable: true,
    },
    {
      labelText: "confirm_parent_password",
      inputType: "password",
      inputValue: confirmParentPassword,
      setValue: setConfirmParentPassword,
      isRequired: true,
      isEditable: true,
    },
  ];

  const formDataChild = [
    {
      labelText: "child_username",
      inputType: "text",
      inputValue: childName,
      setValue: setChildName,
      isRequired: true,
      isEditable: true,
    },
  ];

  const selectAccessData = {
    labelText: "child_access",
    inputValue: childAccess.limited ? "Limited" : "Unlimited",
    setValue: (value) =>
      setChildAccess({ ...childAccess, limited: value === "Limited" }),
    choices: ["Unlimited", "Limited"],
    isRequired: true,
  };
  // console.log("childAccess.limited", childAccess.limited);

  const selectNotificationData = [
    {
      labelText: "child_achievements",
      inputValue: childNotifications.achievements ? "On" : "Off",
      setValue: (value) =>
        setChildNotifications({
          ...childNotifications,
          achievements: value === "On",
        }),
      choices: ["On", "Off"],
      isRequired: true,
    },
    {
      labelText: "child_needs",
      inputValue: childNotifications.needs ? "On" : "Off",
      setValue: (value) =>
        setChildNotifications({ ...childNotifications, needs: value === "On" }),
      choices: ["On", "Off"],
      isRequired: true,
    },
    {
      labelText: "child_reports",
      inputValue:
        childNotifications.journeyReport === 0
          ? "Never"
          : childNotifications.journeyReport === 1
            ? "Weekly"
            : childNotifications.journeyReport === 2
              ? "Monthly"
              : "Yearly",
      setValue: (value) =>
        setChildNotifications({
          ...childNotifications,
          journeyReport:
            value === "Never"
              ? 0
              : value === "Weekly"
                ? 1
                : value === "Monthly"
                  ? 2
                  : 3,
        }),
      choices: ["Never", "Weekly", "Monthly", "Yearly"],
      isRequired: true,
    },
  ];

  return (
    <>
      <div className="settings-container">
        <h1>{t("settingsPage.title")}</h1>
        <div className="settings-forms">
          <form onSubmit={handleSubmit(formatAndSendUpdate)}>
            <div className="form-container">
              <div className="form-parent">
                <h2>{t("settingsPage.title_parents")}</h2>
                {formDataParent.map((formField, index) => (
                  <SingleForm
                    key={index}
                    {...formField}
                    register={register}
                    errors={errors}
                  />
                ))}
              </div>

              <div className="form-child">
                <h2>{t("settingsPage.title_child")}</h2>
                {formDataChild.map((formField, index) => (
                  <SingleForm
                    key={index}
                    {...formField}
                    register={register}
                    errors={errors}
                  />
                ))}
              </div>
            </div>

            <div className="select-container">
              <div className="select-container-left">
                <SelectForm {...selectAccessData} />

                {childAccess.limited && (
                  <div className="day-counters">
                    {days.map((day, index) => (
                      <DayCounter
                        key={index}
                        day={day}
                        inputValue={childAccess[day]}
                        setDaysCount={setChildAccess}
                      />
                    ))}
                  </div>
                )}
              </div>

              <div className="select-container-right">
                {selectNotificationData.map((selectField, index) => (
                  <SelectForm key={index} {...selectField} />
                ))}
              </div>
            </div>

            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <input type="submit" value={t("settingsPage.update")} />
            {!isLoading &&
              !errorMessage &&
              responseData?.data?.statusMessage ===
              "the update was successfull" ? (
              <div className="settings-container">
                <p>Account successfully updated !</p>
              </div>
            ) : null}
          </form>
          {isLoading && <p>Loading...</p>}
        </div>
      </div>
    </>
  );
};

export default Settings;
