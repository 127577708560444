import React from "react";

import "./detail-card.scss";

export interface IconProps {
  src: string;
  alt: string;
  onClick?: () => void;
}

interface DetailCardProps {
  hasHeader?: boolean;
  headerTitle?: string;
  hasSubtitle?: boolean;
  subtitleContent?: string;
  iconLeft?: IconProps[] | null;
  iconRight?: IconProps[] | React.ReactNode | null;
  iconSize?: "small" | "medium" | "big";
  children: React.ReactNode;
  themeColor: string;
  extraTopComponent?: React.ReactNode;
  bottomLeftInfo?: string;
  bottomRightInfo?: string;
}

const DetailCard: React.FC<DetailCardProps> = (props: DetailCardProps) => {
  const iconClass = `detail-card__icon icon--${props.iconSize || "medium"}`;

  return (
    <div className="detail-card">
      {props.extraTopComponent && (
        <div className="detail-card__extra-component">
          {props.extraTopComponent}
        </div>
      )}
      {props.hasHeader && (
        <div className="detail-card__header">
          {props.iconLeft && (
            <div className="detail-card__icon-container--left">
              {props.iconLeft.map((icon, index) =>
                icon ? (
                  <img
                    key={index}
                    className={iconClass}
                    src={icon.src}
                    alt={icon.alt}
                  />
                ) : null
              )}
            </div>
          )}

          {props.headerTitle && (
            <div className="detail-card__title">{props.headerTitle}</div>
          )}

          {props.iconRight && (
            <div className="detail-card__icon-container--right">
              {Array.isArray(props.iconRight)
                ? props.iconRight.map((icon, index) => (
                    <img
                      key={index}
                      className={iconClass}
                      src={icon.src}
                      alt={icon.alt}
                      onClick={icon.onClick}
                    />
                  ))
                : props.iconRight}
            </div>
          )}
        </div>
      )}
      {props.hasSubtitle && props.subtitleContent && (
        <div
          className="detail-card__subtitle"
          style={{ color: props.themeColor }}
        >
          {props.subtitleContent}
        </div>
      )}
      <div className="detail-card__content">{props.children}</div>

      <div className="detail-card__footer">
        {props.bottomLeftInfo ? <div>{props.bottomLeftInfo}</div> : null}
        {props.bottomRightInfo ? <div>{props.bottomRightInfo}</div> : null}
      </div>
    </div>
  );
};

export default DetailCard;
