import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./settings-notifications.scss";

import TitleSwitch from "./notifs/title-switch";
import TitleDropdown from "./notifs/title-dropdown";
import {
  AccessObject,
  AnalyticsAccountDataType,
  NotificationObject,
} from "../../../interfaces/analytics-interfaces/analytics-interface";

interface SettingsNotificationsProps {
  updateNotification: (
    childId: number,
    name: string,
    value: boolean | number
  ) => void;
  childrenSettings: {
    ChildId: number;
    ChildName: string;
    Access: AccessObject;
    Notification: NotificationObject;
  }[];
}

const SettingsNotifications: React.FC<SettingsNotificationsProps> = (
  props: SettingsNotificationsProps
) => {
  const { t } = useTranslation();

  const [isAllNotificationsActive, setIsAllNotificationsActive] =
    useState(true);
  const [isAchievementActive, setIsAchievementActive] = useState(true);
  const [isNeedActive, setIsNeedActive] = useState(true);
  const [isReportActive, setIsReportActive] = useState(true);

  const dropdownData = [
    {
      name: t("analyticsPages.settings_page.child_reports"),
      options: [
        t("analyticsPages.settings_page.child_reports_never"),
        t("analyticsPages.settings_page.child_reports_weekly"),
        t("analyticsPages.settings_page.child_reports_monthly"),
        t("analyticsPages.settings_page.child_reports_annual"),
      ],
      optionValues: [0, 1, 2, 3],
    },
  ];

  const handleJourneyReportChange = (childId: number, selected: number) => {
    props.updateNotification(childId, "journeyReport", selected);
  };

  const toggleAllNotifications = (isActive: boolean) => {
    setIsAllNotificationsActive(isActive);
    setIsAchievementActive(isActive);
    setIsNeedActive(isActive);

    if (!isActive) {
      props.childrenSettings.forEach((childSettings) => {
        props.updateNotification(childSettings.ChildId, "achievements", false);
        props.updateNotification(childSettings.ChildId, "needs", false);
      });
    }
  };

  const toggleAchievement = (isActive: boolean) => {
    setIsAchievementActive(isActive);

    if (!isActive) {
      props.childrenSettings.forEach((childSettings) => {
        props.updateNotification(childSettings.ChildId, "achievements", false);
      });
    }
  };

  const toggleNeed = (isActive: boolean) => {
    setIsNeedActive(isActive);

    if (!isActive) {
      props.childrenSettings.forEach((childSettings) => {
        props.updateNotification(childSettings.ChildId, "needs", false);
      });
    }
  };

  const toggleReport = (isActive: boolean) => {
    setIsReportActive(isActive);

    if (!isActive) {
      props.childrenSettings.forEach((childSettings) => {
        props.updateNotification(childSettings.ChildId, "journeyReport", 0);
      });
    }
  };

  return (
    <div className="analytics-settings-notifications">
      <div className="analytics-settings-notifications--all-notifs">
        <TitleSwitch
          name={t("analyticsPages.settings_page.child_all_notifs")}
          isTitle={true}
          onSwitchChange={toggleAllNotifications}
          sectionChecked={isAllNotificationsActive}
        />
      </div>

      <TitleSwitch
        name={t("analyticsPages.settings_page.child_achievements")}
        isTitle={true}
        onSwitchChange={toggleAchievement}
        sectionChecked={isAllNotificationsActive}
        isDisabled={!isAllNotificationsActive}
      />
      {props.childrenSettings.map((childSettings, index) => (
        <TitleSwitch
          key={index}
          name={childSettings.ChildName}
          isTitle={false}
          onSwitchChange={(isActive) =>
            props.updateNotification(
              childSettings.ChildId,
              "achievements",
              isActive
            )
          }
          sectionChecked={
            isAchievementActive &&
            Boolean(childSettings.Notification.achievements)
          }
          isDisabled={!isAchievementActive || !isAllNotificationsActive}
        />
      ))}

      <TitleSwitch
        name={t("analyticsPages.settings_page.child_needs")}
        isTitle={true}
        onSwitchChange={toggleNeed}
        sectionChecked={isAllNotificationsActive}
        isDisabled={!isAllNotificationsActive}
      />
      {props.childrenSettings.map((childSettings, index) => (
        <TitleSwitch
          key={index}
          name={childSettings.ChildName}
          isTitle={false}
          onSwitchChange={(isActive) =>
            props.updateNotification(childSettings.ChildId, "needs", isActive)
          }
          sectionChecked={
            isNeedActive && Boolean(childSettings.Notification.needs)
          }
          isDisabled={!isNeedActive || !isAllNotificationsActive}
        />
      ))}

      <TitleSwitch
        name={t("analyticsPages.settings_page.child_reports")}
        isTitle={true}
        onSwitchChange={toggleReport}
        sectionChecked={isAllNotificationsActive}
        isDisabled={!isAllNotificationsActive}
      />
      {dropdownData.map((item, index) =>
        props.childrenSettings.map((childSettings, childIndex) => (
          <TitleDropdown
            key={`${index}-${childIndex}`}
            name={childSettings.ChildName}
            options={item.options}
            optionValues={item.optionValues}
            onOptionChange={(selected) =>
              handleJourneyReportChange(childSettings.ChildId, selected)
            }
            initialSelected={childSettings.Notification.journeyReport}
            disabled={!isReportActive || !isAllNotificationsActive}
          />
        ))
      )}
    </div>
  );
};

export default SettingsNotifications;
