import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import "./navbar-analytics.scss";
import ProfileSelectorPc from "../profile/profile-selector/profile-selector-pc";
import ProfileSelectorMobile from "../profile/profile-selector/profile-selector-mobile";
import wytopiaLogo from "../../resources/images/Wytopia_logo-3.png";
import disconnectIconMobile from "../../resources/images/icons/sortie_violet.png";
import burgerIconMobile from "../../resources/images/icons/menu_violet.png";
import AnalyticsMenu from "../analytics/analytics-menu";
import { menuItems } from "../../constants/analytics-constants";
import { NavbarAnalyticsProps } from "../../interfaces/analytics-interfaces/analytics-interface";

function NavbarAnalytics(props: NavbarAnalyticsProps) {
  const navigate = useNavigate();
  const [connected, setConnected] = useState(false);
  const [activeTab, setActiveTab] = useState("home");
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  useEffect(() => {
    const token = Cookies.get("tokenSession");
    setConnected(!!token);

    const handleVuplexReady = () => {
      // console.log("Vuplex est maintenant prêt");
    };

    if (globalThis.vuplex) {
      // console.log("Vuplex est disponible");
    } else {
      window.addEventListener("vuplexready", handleVuplexReady);
    }

    return () => {
      window.removeEventListener("vuplexready", handleVuplexReady);
    };
  }, []);

  const handleCloseButton = () => {
    if (globalThis.vuplex) {
      globalThis.vuplex.postMessage({ type: "closeButtonClicked" });
      return;
    }
  };

  const handleLogout = () => {
    ["tokenSession", "parentEmail", "parentID", "username"].forEach(
      Cookies.remove
    );
    setConnected(false);

    const delayedAction = globalThis.vuplex
      ? handleCloseButton
      : () => {
          window.location.href = "/";
        };

    setTimeout(delayedAction, 1000);
  };

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  const handleHomeClick = () => {
    navigate("/analytics");
    setBurgerMenuOpen(false);
  };

  return (
    <div className="navbar-user">
      <div className="navbar-user-container">
        {props.isMobileScreenSize ? null : (
          <>
            <div className="nav-user-logo">
              <Link to="/" onClick={() => setActiveTab("about")}>
                <img src={wytopiaLogo} alt="logo wytopia" />
              </Link>
            </div>
          </>
        )}

        {props.isMobileScreenSize && burgerMenuOpen ? (
          <>
            <div className="nav-user-menu-mobile">
              <AnalyticsMenu
                items={menuItems}
                toggleBurgerMenu={toggleBurgerMenu}
                handleHomeClick={handleHomeClick}
              />
            </div>
          </>
        ) : null}

        <div className="nav-user-profile">
          {props.isMobileScreenSize ? (
            <>
              <div className="nav-user-burger">
                <button className="burger-btn" onClick={toggleBurgerMenu}>
                  <img src={burgerIconMobile} alt="Menu Burger Icon" />
                </button>
              </div>

              <ProfileSelectorMobile
                childName={props.currentChildToShow?.childName}
                mobileLiteMode={props.mobileLiteMode}
                familyData={props.familyData}
                currentChildToShow={props.currentChildToShow}
                setCurrentChildToShow={props.setCurrentChildToShow}
              />

              <div className="nav-user-disconnect">
                <button className="logout-btn" onClick={handleLogout}>
                  {props.isVisitingFromUnityApp ? null : (
                    <img src={disconnectIconMobile} alt="Logout Icon" />
                  )}
                </button>
              </div>
            </>
          ) : (
            <ProfileSelectorPc
              childName={props.currentChildToShow?.childName}
              handleLogout={handleLogout}
              familyData={props.familyData}
              currentChildToShow={props.currentChildToShow}
              setCurrentChildToShow={props.setCurrentChildToShow}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default NavbarAnalytics;
