import URLS from "../../config/api-urls";

export interface ParentAccountDetailsRequestBody {
  referentId: number;
  token: string;
}

export const fetchParentAccountDetailsApi = async (
  body: ParentAccountDetailsRequestBody
): Promise<any> => {
  const url = URLS.POST_GetParent;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};
