import React, { useEffect, useState } from "react";
import "chart.js/auto";
import { useTranslation } from "react-i18next";
import { RotatingTriangles } from "react-loader-spinner";

import "./skill-map.scss";

import { useAnalytics } from "../analytics-context";
import {
  Skill,
  FilterType,
} from "../../../interfaces/skill-map-interfaces/skill-map-interface";
import { useSkillProgressData } from "../../../hooks/use-skill-progress-data";
import { filterDataByLevelAndParentId } from "../../../helper/analytics-helpers/skill-map-helpers/skill-map-helper";
import { SkillMapCard } from "../../../components/analytics/skill-map-card/skill-map-card";
import { getLanguageNumber } from "../../../helper/common-helpers/language-helper";
import { ChildToShow } from "../../../interfaces/analytics-interfaces/analytics-interface";

interface SkillMapProps {
  isMobileScreenSize: boolean;
  currentChildToShow: ChildToShow | null;
}

const SkillMap: React.FC<SkillMapProps> = (props: SkillMapProps) => {
  const { t, i18n } = useTranslation();
  const actualLanguage = i18n.language;
  const languageNumber = getLanguageNumber(actualLanguage);

  const { isLoading, error } = useAnalytics();

  const [filterType, setFilterType] = useState<FilterType>("Level1");
  const [filteredData, setFilteredData] = useState<Skill[]>([]);

  const {
    isProcessingSkillProgressData,
    skillLevel1ProgressData,
    skillLevel2ProgressData,
    skillLevel3ProgressData,
  } = useSkillProgressData({
    childrenData: props.currentChildToShow,
    actualLanguage: languageNumber,
  });

  const [selectedParentIdByLevel, setSelectedParentIdByLevel] = useState<
    Record<number, number>
  >({});

  useEffect(() => {
    const newFilterType = `Level1${
      selectedParentIdByLevel[1] ? `Skill${selectedParentIdByLevel[1]}` : ""
    }` as FilterType;
    setFilterType(newFilterType);
  }, [filteredData]);

  useEffect(() => {
    if (
      skillLevel1ProgressData &&
      skillLevel2ProgressData &&
      skillLevel3ProgressData
    ) {
      const newFilteredData = filterDataByLevelAndParentId(
        selectedParentIdByLevel,
        [
          ...skillLevel1ProgressData,
          ...skillLevel2ProgressData,
          ...skillLevel3ProgressData,
        ],
        props.isMobileScreenSize
      );
      setFilteredData(newFilteredData);
    }
  }, [
    skillLevel1ProgressData,
    skillLevel2ProgressData,
    skillLevel3ProgressData,
    selectedParentIdByLevel,
    props.isMobileScreenSize,
  ]);

  const updateSelectedParentIdByLevel = (
    level: number,
    parentId: number | null
  ) => {
    if (level === null) {
      setSelectedParentIdByLevel({});
      return;
    }
    for (let i = level; selectedParentIdByLevel[i]; i++) {
      delete selectedParentIdByLevel[i];
    }

    selectedParentIdByLevel[level] = parentId;
    setSelectedParentIdByLevel({ ...selectedParentIdByLevel });
  };

  const generateDesktopSkillMapCards = () => {
    return [
      <SkillMapCard
        filteredData={skillLevel1ProgressData}
        selectedParentIdByLevel={selectedParentIdByLevel}
        updateSelectedParentIdByLevel={updateSelectedParentIdByLevel}
        filterType={"Level1"}
        displayLevel={1}
        parentSkill={undefined}
      />,
      ...Object.keys(selectedParentIdByLevel).map((level) => {
        const displayLevel = parseInt(level) + 1;
        let parentSkill: Skill;
        if (displayLevel > 1) {
          parentSkill = [
            ...skillLevel1ProgressData,
            ...skillLevel2ProgressData,
          ].find(
            (s) =>
              s.level === displayLevel - 1 &&
              s.id === selectedParentIdByLevel[displayLevel - 1]
          );
        }
        return (
          <SkillMapCard
            key={`skill-map-card-level-${displayLevel}`}
            filteredData={filteredData}
            selectedParentIdByLevel={selectedParentIdByLevel}
            updateSelectedParentIdByLevel={updateSelectedParentIdByLevel}
            filterType={filterType}
            displayLevel={displayLevel}
            parentSkill={parentSkill}
          />
        );
      }),
    ];
  };

  const generatedMobileSkillMapCard = () => {
    let availableLevels = Object.keys(selectedParentIdByLevel)
      .map((level) => parseInt(level))
      .sort((a, b) => b - a);

    let displayLevel =
      availableLevels.length === 0 ? 1 : availableLevels[0] + 1;

    let parentSkill: Skill;
    if (displayLevel > 1) {
      parentSkill = [
        ...skillLevel1ProgressData,
        ...skillLevel2ProgressData,
      ].find(
        (s) =>
          s.level === displayLevel - 1 &&
          s.id === selectedParentIdByLevel[displayLevel - 1]
      );
    }
    return (
      <SkillMapCard
        filteredData={filteredData}
        selectedParentIdByLevel={selectedParentIdByLevel}
        updateSelectedParentIdByLevel={updateSelectedParentIdByLevel}
        filterType={filterType}
        displayLevel={displayLevel}
        parentSkill={parentSkill}
      />
    );
  };

  return (
    <>
      {error ? <p style={{ color: "red" }}>Error: {error.message}</p> : null}

      {isLoading || isProcessingSkillProgressData ? (
        <div style={{ alignSelf: "center" }}>
          <RotatingTriangles
            visible={true}
            height="80"
            width="80"
            ariaLabel="rotating-triangels-loading"
            wrapperClass="rotating-triangels-wrapper"
            colors={["#018cd5", "#de2781", "#f19700"]}
          />
        </div>
      ) : selectedParentIdByLevel[1] ? (
        props.isMobileScreenSize ? (
          generatedMobileSkillMapCard()
        ) : (
          generateDesktopSkillMapCards()
        )
      ) : (
        <SkillMapCard
          filteredData={skillLevel1ProgressData}
          selectedParentIdByLevel={selectedParentIdByLevel}
          updateSelectedParentIdByLevel={updateSelectedParentIdByLevel}
          filterType={"Level1"}
          displayLevel={1}
          parentSkill={undefined}
        />
      )}
    </>
  );
};

export default SkillMap;
