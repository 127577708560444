import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useQuery } from "react-query";

import {
  fetchChildGameProgressesApi,
  ChildProgressesRequestBody,
  fetchChildContentProgressesApi,
} from "../../helper/apiHelper/child-progresses-api";
import {
  fetchChildSkillHistoryApi,
  ChildSkillHistoryRequestBody,
} from "../../helper/apiHelper/child-skill-history-api";
import {
  fetchContentSkillsApi,
  fetchSkillsApi,
  fetchSkillsStatisticsApi,
  SkillsRequestBody,
} from "../../helper/apiHelper/skill-api";
import {
  AnalyticsAccountDataType,
  AnalyticsContextType,
  AnalyticsProviderProps,
  ChildActivityTimeModel,
  ChildContentProgressModel,
  ChildFeedbacksModel,
  ChildGameProgressModel,
  ChildQuizAnswersModel,
  ChildSessionTimeModel,
  ChildSkillHistoryModel,
  ContentModel,
  ContentSkillModel,
  ContentStatisticModel,
  ContentsFeedbackModel,
  GameSettingsModel,
  ParentAccountModel,
  SkillModel,
  SkillsStatisticModel,
} from "../../interfaces/analytics-interfaces/analytics-interface";
import {
  ContentsRequestBody,
  fetchContentsApi,
  fetchContentsFeedbackApi,
  fetchContentsStatisticsApi,
} from "../../helper/apiHelper/content-api";
import {
  ParentAccountDetailsRequestBody,
  fetchParentAccountDetailsApi,
} from "../../helper/apiHelper/parent-api";
import {
  ChildIdRequestBody,
  fetchChildActivityTimeApi,
  fetchChildFeedbacksApi,
  fetchChildQuizAnswersApi,
  fetchChildSessionTimeApi,
  fetchAllChildSessionTimeApi,
  fetchGameSettingsApi,
} from "../../helper/apiHelper/child-api";
import { logAllCookies } from "../../helper/auth-helpers/cookie-helper";

const AnalyticsContext = createContext<AnalyticsContextType | null>(null);

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider");
  }
  return context;
};

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}) => {
  const [analyticsAccountData, setAnalyticsAccountData] =
    useState<AnalyticsAccountDataType>({
      tokenSession: null,
      parentEmail: null,
      parentID: null,
      parentAccountName: null,
      totalChildren: null,
      childrenData: [],
    });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const [parentAccountDetails, setParentAccountDetails] =
    useState<ParentAccountModel | null>(null);

  const [childGameProgressesData, setChildGameProgressesData] =
    useState<ChildGameProgressModel | null>(null);

  const [childContentProgressesData, setChildContentProgressesData] =
    useState<ChildContentProgressModel | null>(null);

  const [childSkillHistoryData, setChildSkillHistoryData] =
    useState<ChildSkillHistoryModel | null>(null);

  const [skillsLevel1Data, setSkillsLevel1Data] = useState<SkillModel | null>(
    null
  );
  const [skillsLevel2Data, setSkillsLevel2Data] = useState<SkillModel | null>(
    null
  );
  const [skillsLevel3Data, setSkillsLevel3Data] = useState<SkillModel | null>(
    null
  );
  const [skillsStatisticsData, setSkillsStatisticsData] =
    useState<SkillsStatisticModel | null>(null);

  const [contentSkillsData, setContentSkillsData] =
    useState<ContentSkillModel | null>(null);

  const [contentsData, setContentsData] = useState<ContentModel | null>(null);

  const [contentsFeedbackData, setContentsFeedbackData] =
    useState<ContentsFeedbackModel | null>(null);

  const [childActivityTimeData, setChildActivityTimeData] =
    useState<ChildActivityTimeModel | null>(null);
  const [childQuizAnswersData, setChildQuizAnswersData] =
    useState<ChildQuizAnswersModel | null>(null);
  const [childFeedbacksData, setChildFeedbacksData] =
    useState<ChildFeedbacksModel | null>(null);
  const [gameSettingsData, setGameSettingsData] =
    useState<GameSettingsModel | null>(null);

  const [contentStatisticsData, setContentStatisticsData] =
    useState<ContentStatisticModel | null>(null);

  const [childSessionTimeData, setChildSessionTimeData] =
    useState<ChildSessionTimeModel | null>(null);
  const [allChildSessionTimeData, setAllChildSessionTimeData] =
    useState<ChildSessionTimeModel | null>(null);

  useEffect(() => {
    const updateAnalyticsData = {
      tokenSession: Cookies.get("tokenSession"),
      parentEmail: Cookies.get("parentEmail"),
      parentID: Cookies.get("parentID"),
      parentAccountName: Cookies.get("parentAccountName"),
      totalChildren: parseInt(Cookies.get("totalChildren") || "0", 10),
      childrenData: [],
    };

    const totalChildren = updateAnalyticsData.totalChildren;
    const childrenData: any[] = [];

    for (let i = 1; i <= totalChildren; i++) {
      const childId = Cookies.get(`child${i}Id`);
      const childName = Cookies.get(`child${i}Name`);
      const avatarId = Cookies.get(`child${i}AvatarId`);
      const token = Cookies.get(`child${i}Token`);
      childrenData.push({ childId, childName, avatarId, token });
    }

    updateAnalyticsData.childrenData = childrenData;

    setAnalyticsAccountData(updateAnalyticsData);
    // logAllCookies();
  }, []);

  const fetchSkillsLevel1 = async (languageId: number) => {
    setIsLoading(true);
    try {
      const requestBody: SkillsRequestBody = {
        Level: 1,
        LanguageId: languageId,
      };
      const result = await fetchSkillsApi(requestBody);
      setSkillsLevel1Data(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchSkillsLevel2 = async (languageId: number) => {
    setIsLoading(true);
    try {
      const requestBody: SkillsRequestBody = {
        Level: 2,
        LanguageId: languageId,
      };
      const result = await fetchSkillsApi(requestBody);
      setSkillsLevel2Data(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchSkillsLevel3 = async (languageId: number) => {
    setIsLoading(true);
    try {
      const requestBody: SkillsRequestBody = {
        Level: 3,
        LanguageId: languageId,
      };
      const result = await fetchSkillsApi(requestBody);
      setSkillsLevel3Data(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSkillsStatistics = async () => {
    setIsLoading(true);
    try {
      const result = await fetchSkillsStatisticsApi();
      setSkillsStatisticsData(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchContentSkills = async () => {
    setIsLoading(true);
    try {
      const result = await fetchContentSkillsApi();
      setContentSkillsData(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchContents = async (languageId: number) => {
    setIsLoading(true);
    try {
      const requestBody: ContentsRequestBody = {
        SourceLanguageId: languageId,
      };
      const result = await fetchContentsApi(requestBody);
      setContentsData(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchContentsFeedback = async () => {
    setIsLoading(true);
    try {
      const result = await fetchContentsFeedbackApi();
      setContentsFeedbackData(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchChildGameProgresses = async (childId: number) => {
    setIsLoading(true);
    try {
      const requestBody: ChildProgressesRequestBody = {
        ChildId: childId,
      };
      const result = await fetchChildGameProgressesApi(requestBody);
      setChildGameProgressesData(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchChildContentProgresses = async (childId: number) => {
    setIsLoading(true);
    try {
      const requestBody: ChildProgressesRequestBody = {
        ChildId: childId,
      };
      const result = await fetchChildContentProgressesApi(requestBody);
      setChildContentProgressesData(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchChildSkillHistory = async (childId: number) => {
    setIsLoading(true);
    try {
      const requestBody: ChildSkillHistoryRequestBody = {
        ChildId: childId,
      };
      const result = await fetchChildSkillHistoryApi(requestBody);
      setChildSkillHistoryData(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchParentAccountDetails = async (
    referentId: number,
    token: string
  ) => {
    setIsLoading(true);
    try {
      const requestBody: ParentAccountDetailsRequestBody = {
        referentId,
        token,
      };
      const result = await fetchParentAccountDetailsApi(requestBody);
      setParentAccountDetails(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchChildActivityTime = async (childId: number) => {
    setIsLoading(true);
    try {
      const requestBody: ChildIdRequestBody = {
        ChildId: childId,
      };
      const result = await fetchChildActivityTimeApi(requestBody);
      setChildActivityTimeData(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchChildQuizAnswers = async (childId: number) => {
    setIsLoading(true);
    try {
      const requestBody: ChildIdRequestBody = {
        ChildId: childId,
      };
      const result = await fetchChildQuizAnswersApi(requestBody);
      setChildQuizAnswersData(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchChildFeedbacks = async (childId: number) => {
    setIsLoading(true);
    try {
      const requestBody: ChildIdRequestBody = {
        ChildId: childId,
      };
      const result = await fetchChildFeedbacksApi(requestBody);
      setChildFeedbacksData(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchGameSettings = async () => {
    setIsLoading(true);
    try {
      const result = await fetchGameSettingsApi();
      setGameSettingsData(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchContentStatistics = async () => {
    setIsLoading(true);
    try {
      const result = await fetchContentsStatisticsApi();
      setContentStatisticsData(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchChildSessionTime = async (childId: number) => {
    setIsLoading(true);
    try {
      const requestBody: ChildIdRequestBody = {
        ChildId: childId,
      };
      const result = await fetchChildSessionTimeApi(requestBody);
      setChildSessionTimeData(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllChildSessionTime = async () => {
    setIsLoading(true);
    try {
      const result = await fetchAllChildSessionTimeApi();
      setAllChildSessionTimeData(result);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AnalyticsContext.Provider
      value={{
        analyticsAccountData,
        isLoading,
        error,
        childGameProgressesData,
        childContentProgressesData,
        childSkillHistoryData,
        skillsLevel1Data,
        skillsLevel2Data,
        skillsLevel3Data,
        skillsStatisticsData,
        contentSkillsData,
        contentsData,
        contentsFeedbackData,
        parentAccountDetails,
        childActivityTimeData,
        childQuizAnswersData,
        childFeedbacksData,
        gameSettingsData,
        contentStatisticsData,
        childSessionTimeData,
        allChildSessionTimeData,
        fetchChildGameProgresses,
        fetchChildContentProgresses,
        fetchChildSkillHistory,
        fetchSkillsLevel1,
        fetchSkillsLevel2,
        fetchSkillsLevel3,
        fetchSkillsStatistics,
        fetchContentSkills,
        fetchContents,
        fetchContentsFeedback,
        fetchParentAccountDetails,
        fetchChildActivityTime,
        fetchChildQuizAnswers,
        fetchChildFeedbacks,
        fetchGameSettings,
        fetchContentStatistics,
        fetchChildSessionTime,
        fetchAllChildSessionTime,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
