import React, { useEffect, useState } from "react";
import { RotatingTriangles } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";

import { useAnalytics } from "../analytics-context";
import { getLanguageNumber } from "../../../helper/common-helpers/language-helper";

import "./search-analytics.scss";
import "../analytics.scss";
import DetailCard from "../../../components/detailCard/detail-card";
import RecommendationTable from "../../../components/analytics/recommendation/recommendation-table";
import { useContentsSkillData } from "../../../hooks/use-contents-skill-data";
import { useContentFeedbackAverage } from "../../../hooks/use-content-feedback-average";
import { useSkillProgressData } from "../../../hooks/use-skill-progress-data";
import {
  extractUniqueSkillsFromContents,
  filterContentsBySkillsAndName,
  sortSkillsBySearchTerm,
} from "../../../helper/analytics-helpers/search/search-helper";
import {
  AnalyticsAccountDataType,
  ChildToShow,
} from "../../../interfaces/analytics-interfaces/analytics-interface";

interface SearchAnalyticsProps {
  currentChildToShow: ChildToShow | null;
  familyData: AnalyticsAccountDataType | null;
}

const SearchAnalytics: React.FC<SearchAnalyticsProps> = (
  props: SearchAnalyticsProps
) => {
  const { t, i18n } = useTranslation();
  const actualLanguage = i18n.language;
  const languageNumber = getLanguageNumber(actualLanguage);
  const { isLoading, error } = useAnalytics();

  const rowsToInitiallyShow = 2;
  const rowsToAdd = 4;

  const { control, watch } = useForm({
    defaultValues: {
      searchTerm: "",
    },
  });
  const searchTerm = watch("searchTerm", "") ?? "";
  const [skillsSearched, setSkillsSearched] = useState([]);

  const {
    skillLevel1ProgressData,
    skillLevel2ProgressData,
    skillLevel3ProgressData,
  } = useSkillProgressData({
    childrenData: props.currentChildToShow,
    actualLanguage: languageNumber,
  });

  const { isProcessingContentsSkillData, contentsWithSkills } =
    useContentsSkillData({
      childrenData: props.currentChildToShow,
      actualLanguage: languageNumber,
      filterActivitiesAlreadyDone: false,
    });

  const { isProcessingContentFeedbackAverage, contentsFeedback } =
    useContentFeedbackAverage();

  const filteredContentsWithSkills = filterContentsBySkillsAndName(
    searchTerm,
    contentsWithSkills,
    skillLevel1ProgressData,
    skillLevel2ProgressData,
    skillLevel3ProgressData
  );

  useEffect(() => {
    if (searchTerm === "") {
      setSkillsSearched([]);
      return;
    }

    let uniqueSkills = extractUniqueSkillsFromContents(
      filteredContentsWithSkills,
      skillLevel1ProgressData,
      skillLevel2ProgressData,
      skillLevel3ProgressData,
      searchTerm
    );

    const sortedSkills = sortSkillsBySearchTerm(uniqueSkills, searchTerm);

    if (JSON.stringify(sortedSkills) !== JSON.stringify(skillsSearched)) {
      setSkillsSearched(sortedSkills);
    }
  }, [searchTerm]);

  return (
    <>
      <h3 style={{ color: "#823d90", margin: "0 12px" }}>
        {t("analyticsPages.search_page.search_title")}
      </h3>

      {error ? <p style={{ color: "red" }}>Error: {error.message}</p> : null}

      {isLoading ||
      isProcessingContentsSkillData ||
      isProcessingContentFeedbackAverage ? (
        <div style={{ alignSelf: "center" }}>
          <RotatingTriangles
            visible={true}
            height="80"
            width="80"
            ariaLabel="rotating-triangels-loading"
            wrapperClass="rotating-triangels-wrapper"
            colors={["#018cd5", "#de2781", "#f19700"]}
          />
        </div>
      ) : (
        <div className="search-analytics__searchbar">
          <Controller
            name="searchTerm"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                placeholder={t("analyticsPages.search_page.search_placeholder")}
              />
            )}
          />
        </div>
      )}

      {searchTerm !== "" &&
        skillsSearched.map((skill, index) => {
          const contentsRelatedToSkill = filteredContentsWithSkills.filter(
            (content) => {
              return content.skills?.some(
                (s) =>
                  s?.skillLevel1Id === skill?.id ||
                  s?.skillLevel2Id === skill?.id ||
                  s?.skillLevel3Id === skill?.id
              );
            }
          );

          return (
            <div className="analytics__stats-content--card" key={index}>
              <DetailCard
                hasHeader={true}
                headerTitle={skill.name}
                themeColor="#823d90"
              >
                <RecommendationTable
                  contents={contentsRelatedToSkill}
                  contentsFeedback={contentsFeedback}
                  rowsToInitiallyShow={rowsToInitiallyShow}
                  rowsToAdd={rowsToAdd}
                  currentChildToShow={props.currentChildToShow}
                  familyData={props.familyData}
                />
              </DetailCard>
            </div>
          );
        })}

      {searchTerm !== "" && (
        <div className="analytics__stats-content--card">
          <DetailCard
            hasHeader={true}
            headerTitle={
              t("analyticsPages.search_page.search_activities") + searchTerm
            }
            themeColor="#823d90"
          >
            <RecommendationTable
              contents={filteredContentsWithSkills}
              contentsFeedback={contentsFeedback}
              rowsToInitiallyShow={rowsToInitiallyShow}
              rowsToAdd={rowsToAdd}
              currentChildToShow={props.currentChildToShow}
              familyData={props.familyData}
            />
          </DetailCard>
        </div>
      )}
    </>
  );
};

export default SearchAnalytics;
