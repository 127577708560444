import moment from "moment";

import { ProcessedSkillData } from "../../hooks/use-skill-progress-period-data";
import { ChildSkillHistoryObject } from "../../interfaces/analytics-interfaces/analytics-interface";

export const processSkillDataByTimeFrame = (
  data: ChildSkillHistoryObject[],
  dateLimit: string
): ProcessedSkillData => {
  const today = moment();
  const threeYearsAgo = today.clone().subtract(3, "years");
  const minDate =
    data && Array.isArray(data)
      ? moment.min([...data.map((d) => moment(d.startDate)), threeYearsAgo])
      : threeYearsAgo;

  const maxDate = today;

  const skillIds =
    data && Array.isArray(data)
      ? Array.from(new Set(data.map((record) => record.skillLevel1Id)))
      : [];

  const dateRange = getDateRange(minDate, maxDate, dateLimit);

  let processedData = initializeProcessedData(skillIds, dateRange, dateLimit);

  data?.forEach(({ skillLevel1Id, points, startDate }) => {
    const timeFrame = formatTimeFrame(startDate, dateLimit);
    const index = processedData[skillLevel1Id].timeFrame.indexOf(timeFrame);

    if (index !== -1) {
      processedData[skillLevel1Id].points[index] += points;
    }
  });

  Object.keys(processedData).forEach((skillId) => {
    let cumulativePoints = 0;
    for (let i = 0; i < processedData[skillId].points.length; i++) {
      cumulativePoints += processedData[skillId].points[i];
      processedData[skillId].points[i] = cumulativePoints;
    }
  });

  return processedData;
};

const getDateRange = (
  minDate: moment.Moment,
  maxDate: moment.Moment,
  dateLimit: string
): string[] => {
  const dateRange: string[] = [];
  let currentDate = minDate.clone();

  while (currentDate <= maxDate) {
    dateRange.push(currentDate.format("YYYY-MM-DD"));
    switch (dateLimit) {
      case "last12Days":
        currentDate.add(1, "days");
        break;
      case "last12Weeks":
        currentDate.add(1, "weeks");
        break;
      case "last12Months":
        currentDate.add(1, "months");
        break;
      case "last5Years":
        currentDate.add(1, "years");
        break;
      default:
        currentDate.add(1, "days");
    }
  }

  return dateRange;
};
const initializeProcessedData = (
  skillIds: (string | number)[],
  dateRange: string[],
  dateLimit: string
): ProcessedSkillData => {
  const processedData: ProcessedSkillData = {};

  let limit = 0;
  switch (dateLimit) {
    case "last12Days":
      limit = 12;
      break;
    case "last12Weeks":
      limit = 12;
      break;
    case "last12Months":
      limit = 12;
      break;
    case "last5Years":
      limit = 5;
      break;
    default:
      limit = 12;
  }

  const lastNDateRanges = dateRange.slice(-limit);

  skillIds.forEach((skillId) => {
    processedData[skillId] = {
      timeFrame: lastNDateRanges.map((date) =>
        formatTimeFrame(date, dateLimit)
      ),
      points: new Array(lastNDateRanges.length).fill(0),
    };
  });

  return processedData;
};

const formatTimeFrame = (date: string, dateLimit: string): string => {
  const momentDate = moment(date);
  let formattedDate: string;

  switch (dateLimit) {
    case "last12Days":
      formattedDate = momentDate.format("YYYY-MM-DD");
      break;
    case "last12Weeks":
      formattedDate = momentDate.format("YYYY-WW");
      break;
    case "last12Months":
      formattedDate = momentDate.format("YYYY-MM");
      break;
    case "last5Years":
      formattedDate = momentDate.format("YYYY");
      break;
    default:
      formattedDate = date;
  }

  return formattedDate;
};

export const humanReadableDate = (date: string, dateLimit: string): string => {
  let m;

  switch (dateLimit) {
    case "last12Days":
      m = moment(date, "YYYY-MM-DD");
      return m.isValid() ? m.format("DMMMYY") : date;
    case "last12Weeks":
      m = moment(date, "YYYY-ww");
      if (m.isValid()) {
        const startOfWeek = m.startOf("week");
        const weekOfMonth = Math.ceil(startOfWeek.date() / 7);
        return `${weekOfMonth}-${startOfWeek.format("MMMYYYY")}`;
      }
      return date;
    case "last12Months":
      m = moment(date, "YYYY-MM");
      return m.isValid() ? m.format("MMMYYYY") : date;
    case "last5Years":
      m = moment(date, "YYYY");
      return m.isValid() ? m.format("YYYY") : date;
    default:
      return date;
  }
};
