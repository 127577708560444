import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./confirmation-popup.scss";

import { CloseIconDetails } from "../../../constants/recommendation-constants";
import { AnalyticsAccountDataType } from "../../../interfaces/analytics-interfaces/analytics-interface";

interface ConfirmationPopupProps {
  childId: number;
  familyData: AnalyticsAccountDataType | null;
  onClose: () => void;
  onConfirmDelete: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    childId: number,
    childToken: string
  ) => void;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = (
  props: ConfirmationPopupProps
) => {
  const { t } = useTranslation();
  const [childName, setChildName] = useState("");
  const [childToken, setChildToken] = useState("");

  useEffect(() => {
    const foundChild = props.familyData?.childrenData?.find(
      (child) => Number(child.childId) === props.childId
    );

    if (foundChild) {
      setChildName(foundChild.childName || "");
      setChildToken(foundChild.token || "");
    }
  }, [props.childId, props.familyData]);

  return (
    <div className="confirmation-popup">
      <div className="confirmation-popup__background"></div>
      <div className="confirmation-popup__container">
        <button
          className="confirmation-popup__header__close"
          onClick={props.onClose}
        >
          <img src={CloseIconDetails.src} alt={CloseIconDetails.alt} />
        </button>
        <div className="recommendation-popup__header">
          <p>{t("settingsPage.delete_popup.delete_title")}</p>
        </div>
        <div className="confirmation-popup__infos">
          <p>
            {t("settingsPage.delete_popup.confirm_delete_child_part1")}
            {childName}
            {t("settingsPage.delete_popup.confirm_delete_child_part2")}
          </p>
        </div>
        <div className="confirmation-popup__choices">
          <button
            className="confirmation-popup__choices--cancel"
            onClick={props.onClose}
          >
            {t("settingsPage.delete_popup.confirm_delete_btn")}
          </button>
          <button
            className="confirmation-popup__choices--impose"
            onClick={(e) => props.onConfirmDelete(e, props.childId, childToken)}
          >
            {t("settingsPage.delete_popup.cancel_delete_btn")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
