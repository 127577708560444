import Cookies from "js-cookie";
import { ResponseData } from "../../interfaces/auth-interfaces/auth-interface";

export const setLoginCookies = (responseData: ResponseData): void => {
  Cookies.set("tokenSession", responseData.data.object.token, {
    expires: 7,
    secure: true,
  });
  Cookies.set("parentEmail", responseData.data.object.email, {
    expires: 7,
    secure: true,
  });
  Cookies.set("parentID", responseData.data.object.referentId, {
    expires: 7,
    secure: true,
  });

  let email = responseData.data.object.email;
  let parentAccountName = email.split("@")[0];
  Cookies.set("parentAccountName", parentAccountName, {
    expires: 7,
    secure: true,
  });

  const children = responseData.data.object.children || [];
  children.forEach((child, index) => {
    Cookies.set(`child${index + 1}Id`, child.childId, {
      expires: 7,
      secure: true,
    });
    Cookies.set(`child${index + 1}Name`, child.userName, {
      expires: 7,
      secure: true,
    });
    Cookies.set(`child${index + 1}AvatarId`, child.avatarId, {
      expires: 7,
      secure: true,
    });
    Cookies.set(`child${index + 1}Token`, child.token, {
      expires: 7,
      secure: true,
    });
  });
  Cookies.set("totalChildren", children.length, {
    expires: 7,
    secure: true,
  });
};

export const removeLoginCookies = () => {
  Cookies.remove("tokenSession");
  Cookies.remove("parentEmail");
  Cookies.remove("parentID");
  Cookies.remove("parentAccountName");

  const totalChildren = parseInt(Cookies.get("totalChildren") || "0");
  for (let i = 1; i <= totalChildren; i++) {
    Cookies.remove(`child${i}Id`);
    Cookies.remove(`child${i}Name`);
  }
  Cookies.remove("totalChildren");
};

export const logAllCookies = (): void => {
  const tokenSession = Cookies.get("tokenSession");
  console.log("cookietokenSession:", tokenSession);

  const parentEmail = Cookies.get("parentEmail");
  console.log("cookieparentEmail:", parentEmail);

  const parentID = Cookies.get("parentID");
  console.log("cookieparentID:", parentID);

  const parentAccountName = Cookies.get("parentAccountName");
  console.log("cookieparentAccountName:", parentAccountName);

  const totalChildren = Cookies.get("totalChildren");
  console.log("cookietotalChildren:", totalChildren);

  if (totalChildren) {
    const total = parseInt(totalChildren);
    for (let i = 1; i <= total; i++) {
      const childId = Cookies.get(`child${i}Id`);
      console.log(`cookiechild${i}Id:`, childId);
      const childName = Cookies.get(`child${i}Name`);
      console.log(`cookiechild${i}Name:`, childName);
    }
  }
};

export const removeChildCookie = (childIndexToRemove: number): void => {
  const totalChildren = parseInt(Cookies.get("totalChildren") || "0");
  if (childIndexToRemove > 0 && childIndexToRemove <= totalChildren) {
    Cookies.remove(`child${childIndexToRemove}Id`);
    Cookies.remove(`child${childIndexToRemove}Name`);
    Cookies.remove(`child${childIndexToRemove}AvatarId`);
    Cookies.remove(`child${childIndexToRemove}Token`);

    for (let i = childIndexToRemove + 1; i <= totalChildren; i++) {
      const nextChildIndex = i - 1;
      Cookies.set(`child${nextChildIndex}Id`, Cookies.get(`child${i}Id`), {
        expires: 7,
        secure: true,
      });
      Cookies.set(`child${nextChildIndex}Name`, Cookies.get(`child${i}Name`), {
        expires: 7,
        secure: true,
      });
      Cookies.set(
        `child${nextChildIndex}AvatarId`,
        Cookies.get(`child${i}AvatarId`),
        { expires: 7, secure: true }
      );
      Cookies.set(
        `child${nextChildIndex}Token`,
        Cookies.get(`child${i}Token`),
        { expires: 7, secure: true }
      );

      Cookies.remove(`child${i}Id`);
      Cookies.remove(`child${i}Name`);
      Cookies.remove(`child${i}AvatarId`);
      Cookies.remove(`child${i}Token`);
    }

    Cookies.set("totalChildren", totalChildren - 1, {
      expires: 7,
      secure: true,
    });
  }
};
