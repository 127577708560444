import React from "react";
import { RotatingTriangles } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

import "./progress.scss";

import DetailCard from "../../../components/detailCard/detail-card";
import { useAnalytics } from "../analytics-context";
import { ChildToShow } from "../../../interfaces/analytics-interfaces/analytics-interface";
import ProgressionBarChart from "../../../components/analytics/progression/progression-bar-chart";
import {
  StarBlueDetails,
  StarRoseDetails,
  StarYellowDetails,
} from "../../../constants/skill-map-constants";
import { useSkillProgressPeriodData } from "../../../hooks/use-skill-progress-period-data";

interface ProgressProps {
  currentChildToShow: ChildToShow | null;
  dateFilter: string;
}

const Progress: React.FC<ProgressProps> = (props: ProgressProps) => {
  const { t } = useTranslation();
  const { isLoading, error } = useAnalytics();

  const { isProcessingSkillProgressPeriodData, skillProgressPeriodData } =
    useSkillProgressPeriodData({
      childrenData: props.currentChildToShow,
      dateLimit: props.dateFilter,
    });

  const skillConfigurations = [
    {
      title: "analyticsPages.progress_page.skill_1_title",
      color: "#018cd5",
      icon: [StarBlueDetails],
      index: 1,
    },
    {
      title: "analyticsPages.progress_page.skill_2_title",
      color: "#de2781",
      icon: [StarRoseDetails],
      index: 2,
    },
    {
      title: "analyticsPages.progress_page.skill_3_title",
      color: "#f19700",
      icon: [StarYellowDetails],
      index: 3,
    },
  ];

  return (
    <>
      {error ? <p style={{ color: "red" }}>Error: {error.message}</p> : null}

      {isLoading || isProcessingSkillProgressPeriodData ? (
        <div style={{ alignSelf: "center" }}>
          <RotatingTriangles
            visible={true}
            height="80"
            width="80"
            ariaLabel="rotating-triangels-loading"
            wrapperClass="rotating-triangels-wrapper"
            colors={["#018cd5", "#de2781", "#f19700"]}
          />
        </div>
      ) : (
        // !skillProgressPeriodData ? (
        // <div style={{ textAlign: "center", margin: "20px 0" }}>
        //   <p>{t("analyticsPages.screen_time_page.no_data_available")}</p>
        // </div>
        // ) :
        <>
          {skillConfigurations.map((config, i) => (
            <DetailCard
              key={i}
              hasHeader={true}
              headerTitle={t(config.title)}
              themeColor="#823d90"
              iconLeft={config.icon}
            >
              <ProgressionBarChart
                colors={[config.color]}
                labels={skillProgressPeriodData[config.index]?.timeFrame}
                dataset={skillProgressPeriodData[config.index]?.points}
                dateFilter={props.dateFilter}
              />
            </DetailCard>
          ))}
        </>
      )}
    </>
  );
};

export default Progress;
