import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import bcrypt from "bcryptjs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import "./login.scss";

import URLS from "../../config/api-urls";
import {
  logAllCookies,
  setLoginCookies,
} from "../../helper/auth-helpers/cookie-helper";
import {
  LoginData,
  ResponseData,
} from "../../interfaces/auth-interfaces/auth-interface";

function Login({ setIsAuthenticated }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const emailParam = urlParams.get("parentEmail");
  const childId = urlParams.get("childId");

  const [emailFromUrl, setEmailFromUrl] = useState<string>(
    Cookies.get("parentEmail") || ""
  );

  const [loginData, setLoginData] = useState<LoginData | string>("");
  const [responseData, setResponseData] = useState<ResponseData | null>(null);

  const [userList, setUserList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (emailParam) {
      setEmailFromUrl(emailParam);
      setValue("username", emailParam);
      Cookies.set("parentEmail", emailParam, { expires: 7, secure: true });
    }
  }, [location.search]);

  useEffect(() => {
    if (!_.isEmpty(responseData)) {
      const regex = /connection success/;

      if (regex.test(responseData.data.statusMessage)) {
        // console.log("responseData:", responseData);
        if (typeof responseData !== "undefined") {
          setLoginCookies(responseData);
          setIsAuthenticated(true);

          navigate(`/analytics?childId=${childId}`);
        } else {
          // console.log("responseData est undefined");
        }
      }
    }
  }, [responseData]);

  const formatDataForLogin = (data) => {
    const { username, password } = data;

    const formattedData = {
      email: username,
      password,
    };

    setLoginData(formattedData);

    setIsLoading(true);
    onSubmit(formattedData);
  };

  const onSubmit = (data) => {
    axios
      .post(URLS.POST_ConnectWeb, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (response && response.data && response.data.statusMessage) {
          setResponseData(response);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la requête :", error);
        if (error && error.response && error.response.data) {
          setResponseData(error.response);
        }
        setIsLoading(false);
      });
  };

  const handleInputFieldClick = (event) => {
    if (globalThis.vuplex) {
      globalThis.vuplex.postMessage({ type: "inputFieldClicked" });
      console.log("inputField Clicked");
    }

    const inputElement = event.target;
    if (inputElement && inputElement.focus) {
      inputElement.focus();
    }
  };

  return (
    <div className="login-page">
      <div className="login-page__container">
        <h2>{t("loginPage.connection")}</h2>
        <div className="login-page__container-inputs">
          <form onSubmit={handleSubmit(formatDataForLogin)}>
            <label>{t("loginPage.parent_email")}</label>
            <div className="login-page__inputs login-page__username-inputs">
              <input
                autoComplete="username"
                defaultValue={emailFromUrl}
                onClick={(e) => handleInputFieldClick(e)}
                className={`login-page__input ${
                  errors.username && "login-page__input--error"
                }`}
                {...register("username", {
                  required: true,
                  maxLength: 45,
                  minLength: 4,
                })}
              />
              <Link className="" to="/resendParentConfigMail">
                {t("loginPage.resend_parent_configuration_email")}
              </Link>
            </div>

            <label>{t("loginPage.parent_password")}</label>
            <div className="login-page__inputs login-page__inputs--password">
              <input
                type={"password"}
                autoComplete="current-password"
                onClick={(e) => handleInputFieldClick(e)}
                className={`login-page__input ${
                  errors.password && "login-page__input--error"
                }`}
                {...register("password", {
                  required: true,
                  maxLength: 32,
                  minLength: 4,
                })}
              />
              <Link className="" to="/forgotPassword">
                {t("loginPage.forgot_password")}
              </Link>
            </div>

            {Object.keys(errors).length !== 0 && (
              <p className="login-page__error-message">
                {t("forms.error.incorrect_field")}
              </p>
            )}

            <input
              type="submit"
              value={t("loginPage.connection")}
              className="login-page__submit-button"
            />
          </form>

          <div className="login-page__no-account">
            <Link className="" to="/signup">
              {t("loginPage.no_account")}
            </Link>
          </div>

          {responseData &&
          Object.keys(responseData).length !== 0 &&
          !responseData?.data?.statusMessage.includes("connection success") ? (
            <div className="login-page__submit-message">
              <p>{responseData?.data?.statusMessage}</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Login;
