import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dropdown from "react-dropdown";

import "./analytics.scss";

import AnalyticsMenu from "../../components/analytics/analytics-menu";
import SkillMap from "./skillMap/skill-map";
import Recommendations from "./recommendations/recommendations";
import { menuItems } from "../../constants/analytics-constants";
import {
  getCurrentMenuData,
  getFormattedDate,
} from "../../helper/analytics-helpers/analytics-helper";
import { AnalyticsProps } from "../../interfaces/analytics-interfaces/analytics-interface";
import SearchAnalytics from "./search-analytics/search-analytics";
import SettingsAnalytics from "./settings-analytics/settings-analytics";
import { useAnalytics } from "./analytics-context";
import HistoryAnalytics from "./history/history-analytics";
import ActivitiesAnalytics from "./activities/activities-analytics";
import ScreenTime from "./screen-time/screen-time";
import Progression from "./progression/progress";
import Summary from "./summary/summary";
import Admin from "./admin/admin";

function Analytics(props: AnalyticsProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const { analyticsAccountData } = useAnalytics();
  const [dateFilter, setDateFilter] = useState<string>("week");
  const [dateProgressFilter, setDateProgressFilter] =
    useState<string>("last12Days");
  const dateFilterStates = ["today", "week", "month", "year"];
  const dateProgressFilterStates = [
    "last12Days",
    "last12Weeks",
    "last12Months",
    "last5Years",
  ];

  useEffect(() => {
    if (analyticsAccountData) {
      props.setFamilyData(analyticsAccountData);

      const isValidCurrentChild = analyticsAccountData.childrenData.some(
        (child) => child.childId === props.currentChildToShow?.childId
      );

      if (!isValidCurrentChild) {
        props.setCurrentChildToShow(analyticsAccountData.childrenData[0]);
      }
    }
  }, [analyticsAccountData, props]);

  const { currentMenuItem } = getCurrentMenuData(location.pathname, menuItems);

  const translatedMenuLabel = currentMenuItem
    ? t(`analyticsPages.analytics_menu_items.${currentMenuItem.label}`)
    : "";

  const formattedDate = getFormattedDate();

  const handleDateDropdownOptionChange = (
    selected: number,
    isProgress: boolean = false
  ) => {
    let states: string[];
    if (isProgress) {
      states = dateProgressFilterStates;
    } else {
      states = dateFilterStates;
    }

    const filter = states[selected];
    if (isProgress) {
      setDateProgressFilter(filter);
    } else {
      setDateFilter(filter);
    }
  };

  return (
    <div className="analytics">
      {props.isMobileScreenSize ? null : (
        <>
          <div className="analytics__menu">
            <AnalyticsMenu items={menuItems} />
          </div>
        </>
      )}

      <div className="analytics__stats">
        <div className="analytics__stats-header">
          <div className="analytics__stats-header-space"></div>
          <div className="analytics__stats-header-title">
            {currentMenuItem ? (
              <>
                <img
                  className="icon-title"
                  src={currentMenuItem.iconViolet}
                  alt={currentMenuItem.label}
                />
                {translatedMenuLabel}
              </>
            ) : (
              t("analyticsPages.analytics_menu_items.summary") +
              " " +
              props?.currentChildToShow?.childName
            )}
          </div>

          <div className="analytics__stats-header-date">
            {(() => {
              if (currentMenuItem) {
                switch (currentMenuItem.route) {
                  case "/analytics/progress":
                    return (
                      <Dropdown
                        className="analytics__stats-header-date--dropdown"
                        options={dateProgressFilterStates.map((state) =>
                          t(`analyticsPages.progress_page.${state}`)
                        )}
                        value={t(
                          `analyticsPages.progress_page.${dateProgressFilter}`
                        )}
                        onChange={(option) => {
                          const selectedValue = option.value;
                          const selectedIdx =
                            dateProgressFilterStates.findIndex(
                              (state) =>
                                t(`analyticsPages.progress_page.${state}`) ===
                                selectedValue
                            );
                          handleDateDropdownOptionChange(selectedIdx, true);
                        }}
                      />
                    );

                  case "/analytics/history":
                  case "/analytics/activities":
                    return (
                      <Dropdown
                        className="analytics__stats-header-date--dropdown"
                        options={dateFilterStates.map((state) =>
                          t(`analyticsPages.history_page.${state}`)
                        )}
                        value={t(`analyticsPages.history_page.${dateFilter}`)}
                        onChange={(option) => {
                          const selectedValue = option.value;
                          const selectedIdx = dateFilterStates.findIndex(
                            (state) =>
                              t(`analyticsPages.history_page.${state}`) ===
                              selectedValue
                          );
                          handleDateDropdownOptionChange(selectedIdx, false);
                        }}
                      />
                    );
                  case "/analytics/search":
                  case "/analytics/settings":
                  case "/analytics/admin":
                    return null;
                  default:
                    return `Today, ${formattedDate}`;
                }
              } else {
                return `Today, ${formattedDate}`;
              }
            })()}
          </div>
        </div>

        <div className="analytics__stats-content">
          <Routes>
            <Route
              path="*"
              element={
                props.currentChildToShow ? (
                  <Summary
                    key={props.currentChildToShow.childId}
                    currentChildToShow={props.currentChildToShow}
                  />
                ) : null
              }
            />
            <Route
              path="screen-time"
              element={
                props.currentChildToShow ? (
                  <ScreenTime
                    key={props.currentChildToShow.childId}
                    currentChildToShow={props.currentChildToShow}
                  />
                ) : null
              }
            />
            <Route
              path="history"
              element={
                props.currentChildToShow ? (
                  <HistoryAnalytics
                    key={props.currentChildToShow.childId}
                    currentChildToShow={props.currentChildToShow}
                    dateFilter={dateFilter}
                  />
                ) : null
              }
            />
            <Route
              path="activities"
              element={
                props.currentChildToShow ? (
                  <ActivitiesAnalytics
                    key={props.currentChildToShow.childId}
                    currentChildToShow={props.currentChildToShow}
                    dateFilter={dateFilter}
                  />
                ) : null
              }
            />
            <Route
              path="skill-map"
              element={
                props.currentChildToShow ? (
                  <SkillMap
                    key={props.currentChildToShow.childId}
                    isMobileScreenSize={props.isMobileScreenSize}
                    currentChildToShow={props.currentChildToShow}
                  />
                ) : null
              }
            />
            <Route
              path="progress"
              element={
                props.currentChildToShow ? (
                  <Progression
                    key={props.currentChildToShow.childId}
                    currentChildToShow={props.currentChildToShow}
                    dateFilter={dateProgressFilter}
                  />
                ) : null
              }
            />
            <Route
              path="recommendations"
              element={
                props.currentChildToShow ? (
                  <Recommendations
                    key={props.currentChildToShow.childId}
                    currentChildToShow={props?.currentChildToShow}
                    familyData={props.familyData}
                  />
                ) : null
              }
            />
            <Route
              path="search"
              element={
                props.currentChildToShow ? (
                  <SearchAnalytics
                    key={props.currentChildToShow.childId}
                    currentChildToShow={props.currentChildToShow}
                    familyData={props.familyData}
                  />
                ) : null
              }
            />
            <Route
              path="rewards"
              element={
                props.currentChildToShow ? (
                  <p
                    style={{
                      textAlign: "center",
                      alignSelf: "center",
                      marginBottom: "48px",
                    }}
                  >
                    Work in progress . . .{" "}
                  </p>
                ) : null
              }
            />
            <Route
              path="settings"
              element={
                props.currentChildToShow ? (
                  <>
                    <p
                      style={{
                        textAlign: "center",
                        alignSelf: "center",
                        marginBottom: "48px",
                      }}
                    >
                      Work in progress . . .{" "}
                    </p>
                    <SettingsAnalytics
                      key={props.currentChildToShow.childId}
                      currentChildToShow={props.currentChildToShow}
                      familyData={props.familyData}
                      setFamilyData={props.setFamilyData}
                    />
                  </>
                ) : null
              }
            />
            <Route
              path="admin"
              element={
                props.currentChildToShow ? (
                  <>
                    <p
                      style={{
                        textAlign: "center",
                        alignSelf: "center",
                        marginBottom: "48px",
                      }}
                    >
                      Users stats
                    </p>
                    <Admin />
                  </>
                ) : null
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
