import React, { useEffect, useState } from "react";
import { RotatingTriangles } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

import { useAnalytics } from "../analytics-context";
import { getLanguageNumber } from "../../../helper/common-helpers/language-helper";

import "./settings-analytics.scss";
import DetailCard from "../../../components/detailCard/detail-card";
import {
  AlertIconDetails,
  CheckIconDetails,
  LockIconDetails,
  ScreenTimeIconDetails,
  SleepModeIconDetails,
} from "../../../constants/settings-analytics-constants";
import SettingsNotifications from "./settings-notifications";
import { SettingsFormData } from "../../../interfaces/settings-interfaces/settings-analytics-interface";
import SettingsSecurity from "./settings-security";
import {
  AnalyticsAccountDataType,
  ChildToShow,
} from "../../../interfaces/analytics-interfaces/analytics-interface";

interface SettingsAnalyticsProps {
  currentChildToShow: ChildToShow | null;
  familyData: AnalyticsAccountDataType | null;
  setFamilyData: (familyData: AnalyticsAccountDataType | null) => void;
}

const SettingsAnalytics: React.FC<SettingsAnalyticsProps> = (
  props: SettingsAnalyticsProps
) => {
  const { t, i18n } = useTranslation();
  const actualLanguage = i18n.language;
  const languageNumber = getLanguageNumber(actualLanguage);
  const {
    isLoading,
    error,
    analyticsAccountData,
    parentAccountDetails,
    fetchParentAccountDetails,
  } = useAnalytics();
  const [allRequestsFetched, setAllRequestsFetched] = useState<boolean>(false);

  const [formData, setFormData] = useState<SettingsFormData>({
    Email: "",
    TokenCreation: "",
    Password: "",
    ReferentId: 0,
    Children: [],
  });
  // console.log("formData:", formData);
  const [initialFormData, setInitialFormData] =
    useState<SettingsFormData | null>(null);
  const [isModified, setIsModified] = useState<boolean>(false);

  useEffect(() => {
    if (analyticsAccountData.parentID && analyticsAccountData.tokenSession) {
      Promise.all([
        fetchParentAccountDetails(
          Number(analyticsAccountData.parentID),
          analyticsAccountData.tokenSession
        ),
      ]).then(() => {
        setAllRequestsFetched(true);
      });
    }
  }, [analyticsAccountData]);

  useEffect(() => {
    if (parentAccountDetails && parentAccountDetails.status === 1) {
      const initialFormData: SettingsFormData = {
        Email: parentAccountDetails.object.email,
        TokenCreation: "",
        Password: "",
        ReferentId: parentAccountDetails.object.referentId,
        Children: parentAccountDetails.object.children.map((child) => ({
          ChildId: child.childId,
          ChildName: child.userName,
          Access: child.access,
          Notification: child.notification,
        })),
      };
      setFormData(initialFormData);
      setInitialFormData(initialFormData);
    }
  }, [parentAccountDetails]);

  useEffect(() => {
    if (initialFormData) {
      setIsModified(
        JSON.stringify(initialFormData) !== JSON.stringify(formData)
      );
    }
  }, [formData, initialFormData]);

  const updateNotification = (
    childId: number,
    key: string,
    value: boolean | number
  ) => {
    setFormData((prev) => {
      const updatedChildren = prev.Children.map((child) => {
        if (child.ChildId !== childId) {
          return child;
        }
        return {
          ...child,
          Notification: {
            ...child.Notification,
            [key]: value,
          },
        };
      });
      return { ...prev, Children: updatedChildren };
    });
  };

  const updateJourneyReport = (childId: number, value: number) => {
    setFormData((prev) => {
      const updatedChildren = prev.Children.map((child) => {
        if (child.ChildId !== childId) {
          return child;
        }
        return {
          ...child,
          Notification: {
            ...child.Notification,
            journeyReport: value,
          },
        };
      });
      return { ...prev, Children: updatedChildren };
    });
  };

  const updateAccess = (childId: number, key: string, value: number) => {
    setFormData((prev) => {
      const updatedChildren = prev.Children.map((child) => {
        if (child.ChildId !== childId) {
          return child;
        }
        return {
          ...child,
          Access: {
            ...child.Access,
            [key]: value,
          },
        };
      });
      return { ...prev, Children: updatedChildren };
    });
  };

  const updateSecurity = (key: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = () => {
    // Envoyez formData à votre API
  };

  return (
    <>
      {/* <DetailCard
        hasHeader={true}
        headerTitle={t("analyticsPages.settings_page.card_max_screen_time")}
        iconLeft={[ScreenTimeIconDetails]}
        themeColor="#823d90"
      >
        <p>TO DO</p>
      </DetailCard>

      <DetailCard
        hasHeader={true}
        headerTitle={t("analyticsPages.settings_page.card_sleep_mode")}
        iconLeft={[SleepModeIconDetails]}
        themeColor="#823d90"
      >
        <p>TO DO</p>
        // <AccessControl updateAccess={updateAccess} /> 
      </DetailCard> 
*/}
      <DetailCard
        hasHeader={true}
        headerTitle={t("analyticsPages.settings_page.card_notifications")}
        iconLeft={[AlertIconDetails]}
        themeColor="#823d90"
      >
        <SettingsNotifications
          updateNotification={updateNotification}
          childrenSettings={formData.Children}
        />
      </DetailCard>

      <DetailCard
        hasHeader={true}
        headerTitle={t("analyticsPages.settings_page.card_security")}
        iconLeft={[LockIconDetails]}
        themeColor="#823d90"
      >
        <SettingsSecurity
          updateSecurity={updateSecurity}
          parentEmail={formData.Email}
          childrenSettings={formData.Children}
          familyData={props.familyData}
          setFamilyData={props.setFamilyData}
        />
      </DetailCard>

      <div className="settings-analytics__submit">
        <button type="button" onClick={handleSubmit} disabled={!isModified}>
          <img src={CheckIconDetails.src} alt={CheckIconDetails.alt} />
          <p>{t("analyticsPages.settings_page.confirm_button")}</p>
        </button>
      </div>
    </>
  );
};

export default SettingsAnalytics;
