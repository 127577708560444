import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import "./about.scss";

import appIos from "../../resources/images/ios_logo.png";
import appAndroid from "../../resources/images/android_logo.png";
import wytopiaAvatar from "../../resources/images/Wytopia_logo-6.png";
import wytopiaQrCode from "../../resources/images/landing-page/wytopia-qr-code.png";
import educationalGaming from "../../resources/images/landing-page/education-gaming.png";
import WriteNote from "../../resources/images/landing-page/write-note.png";
import SkillsDiagram from "../../resources/images/landing-page/skills-diagram.png";
import LaptopSecured from "../../resources/images/landing-page/laptop-secured.png";

function ImageGallery() {
  const imagesPath = require.context(
    "../../resources/images/content-thumbnails",
    false,
    /\.(png|jpg|jpeg|gif|svg)$/
  );

  const [imageAnimating, setImageAnimating] = useState(null);
  const allImages = imagesPath.keys().map(imagesPath);

  const selectRandomImages = (images, count) => {
    const shuffled = [...images].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const changeImageWithAnimation = (index) => {
    const updatedImages = [...selectedImages];
    setImageAnimating(index);

    setTimeout(() => {
      let newImage;
      do {
        newImage = selectRandomImages(allImages, 1)[0];
      } while (selectedImages.includes(newImage));
      const newImages = [...selectedImages];
      newImages[index] = newImage;

      setSelectedImages(newImages);
      setImageAnimating(null);

      setTimeout(() => {}, 300);
    }, 300);
  };

  const [selectedImages, setSelectedImages] = useState(
    selectRandomImages(allImages, 8)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const index = Math.floor(Math.random() * selectedImages.length);
      changeImageWithAnimation(index);
    }, 3000);

    return () => clearInterval(interval);
  }, [selectedImages]);

  return (
    <div className="popular-games-contents">
      {selectedImages.map((image, index) => (
        <div
          className="image-container"
          key={index}
          style={{
            transform: imageAnimating === index ? "scale(0)" : "scale(1)",
            transition: "transform 0.3s ease-in-out",
          }}
        >
          <img src={image} alt={`Image ${index}`} />
        </div>
      ))}
    </div>
  );
}

function StepsSection() {
  const { t } = useTranslation();

  return (
    <div className="steps-section">
      <div className="step">
        <div className="step-number">1</div>
        <p className="step-title">
          {t("homePage.howToInstallPresentation.getTheApp")}
        </p>
        <img src={wytopiaQrCode} alt="QR Code" />
      </div>
      <div className="step">
        <div className="step-number">2</div>
        <p className="step-title">
          {t("homePage.howToInstallPresentation.registerForFree")}
        </p>
        <img src={WriteNote} alt="Check" />
        <p className="step-description">
          {t("homePage.howToInstallPresentation.registerForFreeDescription")}
        </p>
      </div>
      <div className="step">
        <div className="step-number">3</div>
        <p className="step-title">
          {t("homePage.howToInstallPresentation.playAndLearn")}
        </p>
        <img src={educationalGaming} alt="Game Controller" />
        <p className="step-description">
          {t("homePage.howToInstallPresentation.playAndLearnDescription")}
        </p>
      </div>
    </div>
  );
}

function About() {
  const { t } = useTranslation();

  return (
    <div className="content-page">
      <div className="about-presentation">
        <div className="about-presentation-container">
          <div className="about-presentation-avatar">
            <img src={wytopiaAvatar} alt="wytopia-avatar" />
          </div>
          <div className="about-presentation-text">
            <div className="about-presentation-title">
              <h1>{t("homePage.aboutPresentation.title")}</h1>
              <h2>{t("homePage.aboutPresentation.subtitle")}</h2>
            </div>
            <ul className="about-presentation-list">
              <li>{t("homePage.aboutPresentation.text1")}</li>
              <li>{t("homePage.aboutPresentation.text2")}</li>
              <li>{t("homePage.aboutPresentation.text3")}</li>
              <li>{t("homePage.aboutPresentation.text4")}</li>
              <li>{t("homePage.aboutPresentation.text5")}</li>
              <li>{t("homePage.aboutPresentation.text6")}</li>
            </ul>
          </div>
          <div className="about-presentation-apps">
            <a
              href="https://play.google.com/store/apps/details?id=com.WytopiaOrg.Wytopia"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="app-android"
                alt="logo download android app"
                src={appAndroid}
              />
            </a>
            <a
              href="https://apps.apple.com/fr/app/wytopia/id6449259890"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="app-ios"
                alt="logo download ios app"
                src={appIos}
              />
            </a>
          </div>
        </div>
      </div>

      <div className="popular-games">
        <h1 className="section-title">
          {t("homePage.popularGamesPresentation.title")}
        </h1>
        <h3 className="section-description">
          {t("homePage.popularGamesPresentation.description")}
        </h3>
        <ImageGallery />
      </div>

      <div className="how-to-presentation">
        <h1 className="section-title">
          {t("homePage.howToInstallPresentation.title")}
        </h1>
        <h3 className="section-description">
          {t("homePage.howToInstallPresentation.description")}
        </h3>
        <StepsSection />
      </div>

      <div className="skill-presentation">
        <div className="skill-container">
          <div className="top-box">{t("homePage.skillPresentation.title")}</div>
          <div className="bottom-container">
            <div className="left-container">
              <div className="left-top-box">
                <p>{t("homePage.skillPresentation.descriptionTopLeft")}</p>
              </div>
              <div className="left-bottom-box">
                <p>{t("homePage.skillPresentation.descriptionBotLeft")}</p>
              </div>
            </div>
            <div className="right-container">
              <img src={SkillsDiagram} alt="skill diagram" />
            </div>
          </div>
        </div>
      </div>

      <div className="rgpd-presentation">
        <h1 className="section-title">
          {t("homePage.rgpdPresentation.title")}
        </h1>
        <div className="rgpd-container">
          <div className="left-container">
            <h3 className="rgpd-description">
              {t("homePage.rgpdPresentation.description")}
            </h3>
          </div>
          <div className="right-container">
            <img src={LaptopSecured} alt="laptop secured" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
