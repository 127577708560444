import {
  ChildActivityTimeModel,
  ChildActivityTimeObject,
  ChildContentProgressModel,
  ChildFeedbacksModel,
  ChildFeedbacksObject,
  ChildGameProgressModel,
  ChildGameProgressObject,
  ChildQuizAnswersModel,
  ChildQuizAnswersObject,
  ContentModel,
  GameSettingsModel,
  GameSettingsObject,
} from "../../interfaces/analytics-interfaces/analytics-interface";
import {
  HistoryData,
  MergedSkillContentData,
} from "../../interfaces/analytics-interfaces/history-interface";
import { SkillLevel2ProgressData } from "../../interfaces/skill-map-interfaces/skill-map-interface";

export const groupDataByContentId = (
  contentData: ContentModel,
  childId: number
): Record<number, HistoryData> => {
  const groupedData: Record<number, HistoryData> = {};

  contentData.object.forEach((content) => {
    groupedData[content.contentId] = {
      contentId: content.contentId,
      activityTitle: content.title || "",
      activityFormat: content.format,
      childId: childId,
      childActivityTime: 0,
      childQuizCorrectAnswers: null,
      activityQuizExpectedAnswers: 3,
      childActivityFeedback: null,
      childGameProgressLevel: null,
      activityGameLevelToReach: null,
      activityStatus: "unknown",
      thumbnail: content.thumbnail,
    };
  });

  return groupedData;
};

export const mergeWithChildData = (
  groupedData: Record<number, HistoryData>,
  childActivityTimeModel: ChildActivityTimeModel,
  childQuizAnswersModel: ChildQuizAnswersModel,
  childFeedbacksModel: ChildFeedbacksModel,
  gameSettingsModel: GameSettingsModel,
  childGameProgressesModel: ChildGameProgressModel
): Record<number, HistoryData> => {
  mergeChildActivityTime(groupedData, childActivityTimeModel.object);
  mergeChildQuizAnswers(groupedData, childQuizAnswersModel.object);
  mergeChildFeedbacks(groupedData, childFeedbacksModel.object);
  mergeGameSettings(groupedData, gameSettingsModel.object);
  mergeChildGameProgresses(groupedData, childGameProgressesModel.object);
  mergeGameActivityStatus(groupedData, childGameProgressesModel.object);

  return groupedData;
};

export const filterAndFormatData = (
  groupedData: Record<number, HistoryData>,
  childContentProgressDataObject: ChildContentProgressModel,
  dateFilter?: string
): HistoryData[] => {
  const childContentProgressData = childContentProgressDataObject.object;
  const dateLimit = getDateLimit(dateFilter);

  const filteredData = Object?.values(groupedData)?.filter((data) =>
    childContentProgressData?.some(
      (progress) => progress.contentId === data.contentId
    )
  );

  const dateIndex: Record<number, string> = {};
  childContentProgressData?.forEach((progress) => {
    dateIndex[progress.contentId] = progress.progressDate;
  });

  const filteredByDate = dateLimit
    ? filteredData.filter(
        (data) => new Date(dateIndex[data.contentId] || "") >= dateLimit
      )
    : filteredData;

  const sortedData = filteredByDate.sort((a, b) => {
    const dateA = dateIndex[a.contentId] || "";
    const dateB = dateIndex[b.contentId] || "";
    return new Date(dateB).getTime() - new Date(dateA).getTime();
  });

  return sortedData;
};

const mergeChildActivityTime = (
  groupedData: Record<number, HistoryData>,
  childActivityTime: ChildActivityTimeObject[]
) => {
  childActivityTime?.forEach((activity) => {
    const data = groupedData[activity.contentId];
    if (data) {
      data.childId = activity.childId;
      data.childActivityTime = activity.activityTime;
    }
  });
};

const mergeChildQuizAnswers = (
  groupedData: Record<number, HistoryData>,
  childQuizAnswers: ChildQuizAnswersObject[]
) => {
  const contentIdToMaxCorrectAnswers: Record<number, number> = {};
  const contentIdToExpectedAnswers: Record<number, number> = {};

  childQuizAnswers?.forEach((quiz) => {
    if (
      !contentIdToMaxCorrectAnswers[quiz.contentId] ||
      contentIdToMaxCorrectAnswers[quiz.contentId] < quiz.correctAnswers
    ) {
      contentIdToMaxCorrectAnswers[quiz.contentId] = quiz.correctAnswers;
    }

    contentIdToExpectedAnswers[quiz.contentId] = quiz.expectedAnswers;
  });

  Object.keys(contentIdToMaxCorrectAnswers).forEach((contentIdStr) => {
    const contentId = parseInt(contentIdStr, 10);
    const data = groupedData[contentId];
    if (data && !data.activityFormat.toLowerCase().includes("game")) {
      data.childQuizCorrectAnswers = contentIdToMaxCorrectAnswers[contentId];
      data.activityQuizExpectedAnswers = contentIdToExpectedAnswers[contentId];

      let activityStatus: "todo" | "doing" | "done" | "unknown" = "unknown";
      const { childQuizCorrectAnswers, activityQuizExpectedAnswers } = data;

      if (
        childQuizCorrectAnswers === null ||
        activityQuizExpectedAnswers === null
      ) {
        activityStatus = "unknown";
      } else if (childQuizCorrectAnswers === 0) {
        activityStatus = "todo";
      } else if (
        childQuizCorrectAnswers > 0 &&
        childQuizCorrectAnswers < activityQuizExpectedAnswers
      ) {
        activityStatus = "doing";
      } else if (childQuizCorrectAnswers === activityQuizExpectedAnswers) {
        activityStatus = "done";
      }

      data.activityStatus = activityStatus;
    }
  });
};

const mergeChildFeedbacks = (
  groupedData: Record<number, HistoryData>,
  childFeedbacks: ChildFeedbacksObject[]
) => {
  childFeedbacks?.forEach((feedback) => {
    const data = groupedData[feedback.contentId];
    if (data) {
      data.childActivityFeedback = feedback.value;
    }
  });
};

const mergeGameSettings = (
  groupedData: Record<number, HistoryData>,
  gameSettings: GameSettingsObject[]
) => {
  gameSettings.forEach((setting) => {
    const data = groupedData[setting.contentId];
    if (
      data &&
      (data.activityFormat === "Games" || data.activityFormat === "Game")
    ) {
      data.activityGameLevelToReach = setting.levelToReach;
    }
  });
};

const mergeChildGameProgresses = (
  groupedData: Record<number, HistoryData>,
  childGameProgresses: ChildGameProgressObject[]
) => {
  childGameProgresses?.forEach((progress) => {
    const data = groupedData[progress.contentId];
    if (
      data &&
      (data.activityFormat === "Games" || data.activityFormat === "Game")
    ) {
      data.childGameProgressLevel = progress.progressLevel;
    }
  });
};

const mergeGameActivityStatus = (
  groupedData: Record<number, HistoryData>,
  childGameProgresses: ChildGameProgressObject[]
) => {
  childGameProgresses?.forEach((progress) => {
    const data = groupedData[progress.contentId];
    if (data && data.activityFormat.toLowerCase().includes("game")) {
      const { childGameProgressLevel, activityGameLevelToReach } = data;
      let activityStatus: "todo" | "doing" | "done" | "unknown" = "unknown";

      if (
        childGameProgressLevel === null ||
        activityGameLevelToReach === null
      ) {
        activityStatus = "unknown";
      } else if (childGameProgressLevel === 0) {
        activityStatus = "todo";
      } else if (
        childGameProgressLevel > 0 &&
        childGameProgressLevel < activityGameLevelToReach
      ) {
        activityStatus = "doing";
      } else if (childGameProgressLevel >= activityGameLevelToReach) {
        activityStatus = "done";
      }

      data.activityStatus = activityStatus;
    }
  });
};

export const getDateLimit = (filter: string): Date | null => {
  const now = new Date();
  switch (filter) {
    case "today":
      return new Date(now.setHours(0, 0, 0, 0));
    case "week":
      return new Date(now.setDate(now.getDate() - 7));
    case "month":
      return new Date(now.setMonth(now.getMonth() - 1));
    case "year":
      return new Date(now.setFullYear(now.getFullYear() - 1));
    default:
      return null;
  }
};

export const transformToSkillFormat = (
  data: MergedSkillContentData
): SkillLevel2ProgressData => {
  return {
    id: data.skillId,
    name: data.skillName,
    progress: data.skillProgress,
    level: data.skillLevel,
    parentId: data.skillParentId,
  };
};

export const filterAndSortSkillHistoryData = (
  historyData: HistoryData[],
  historySkillProgressData: MergedSkillContentData[]
): [MergedSkillContentData[], number] => {
  if (historyData.length === 0 || historySkillProgressData.length === 0) {
    return [[], 0];
  }

  const contentIdsFromHistory = historyData.map((h) => h.contentId);
  const filteredData = historySkillProgressData.filter((m) =>
    contentIdsFromHistory.includes(m.contentId)
  );

  const uniqueSkills: { [key: string]: MergedSkillContentData } = {};
  filteredData.forEach((data) => {
    const uniqueKey = `${data.skillId}-${data.skillLevel}`;
    if (!uniqueSkills[uniqueKey]) {
      uniqueSkills[uniqueKey] = data;
    }
  });

  const uniqueSkillsArray = Object.values(uniqueSkills);

  let totalProgress = 0;
  uniqueSkillsArray.forEach((skill) => {
    totalProgress += skill.skillProgress;
  });

  return [
    uniqueSkillsArray.sort((a, b) => b.skillProgress - a.skillProgress),
    totalProgress,
  ];
};

export const updateSkillHistoryThemeColor = (parentId: number): string => {
  let newThemeColor = "#823d90";
  if (parentId !== null) {
    if (parentId === 1) {
      newThemeColor = "#59c0d1";
    } else if (parentId === 2) {
      newThemeColor = "#e8318b";
    } else if (parentId === 3) {
      newThemeColor = "#e6b10d";
    }
  }

  return newThemeColor;
};
