import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

import "./profile-selector-mobile.scss";

import AvatarAnimal1 from "../../../resources/images/avatars/Plan-1.png";
import arrowLeftIcon from "../../../resources/images/icons/gauche_violet.png";
import arrowRightIcon from "../../../resources/images/icons/droite_violet.png";
import { ProfileSelectorMobileProps } from "../../../interfaces/analytics-interfaces/analytics-interface";
import { findChildIndex } from "../../../helper/common-helpers/params-helper";

const ProfileSelectorMobile: React.FC<ProfileSelectorMobileProps> = (
  props: ProfileSelectorMobileProps
) => {
  const initialIndex = props?.familyData?.childrenData
    ? findChildIndex(
        props.currentChildToShow?.childId,
        props.familyData?.childrenData
      )
    : 0;

  const [currentChildIndex, setCurrentChildIndex] = useState(initialIndex);

  const [fontSize, setFontSize] = React.useState("24px"); // Taille par défaut

  const switchChild = (direction: "left" | "right") => {
    if (
      !props?.familyData?.childrenData ||
      props?.familyData?.childrenData.length === 0
    )
      return;

    const children = props.familyData.childrenData;

    let newIndex = currentChildIndex;

    if (direction === "left") {
      newIndex = newIndex === 0 ? children.length - 1 : newIndex - 1;
    } else {
      newIndex = (newIndex + 1) % children.length;
    }

    setCurrentChildIndex(newIndex);
    props.setCurrentChildToShow(children[newIndex]);
    // Cookies.remove("unityChildId");
  };

  useEffect(() => {
    const newIndex = props.familyData?.childrenData
      ? findChildIndex(
          props.currentChildToShow?.childId,
          props.familyData?.childrenData
        )
      : 0;
    setCurrentChildIndex(newIndex);
  }, [props.currentChildToShow, props.familyData]);

  return (
    <div className="profile-selector-mobile">
      <div className="top-section">
        <img
          src={arrowLeftIcon}
          onClick={() => switchChild("left")}
          alt="Arrow Left"
          className="arrow-icon"
        />
        <img src={AvatarAnimal1} alt="User Avatar" className="avatar" />
        <img
          src={arrowRightIcon}
          onClick={() => switchChild("right")}
          alt="Arrow Right"
          className="arrow-icon"
        />
      </div>
      <div className="bot-section">
        <div className="profile-username" style={{ fontSize: fontSize }}>
          {props.childName?.toUpperCase()}
        </div>
      </div>
    </div>
  );
};

export default ProfileSelectorMobile;
