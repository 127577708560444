import React, { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./title-dropdown.scss";

interface TitleDropdownProps {
  name: string;
  options: string[];
  optionValues: number[];
  onOptionChange: (selected: number) => void;
  disabled?: boolean;
  initialSelected?: number;
}

const TitleDropdown: React.FC<TitleDropdownProps> = (
  props: TitleDropdownProps
) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    props.options[props.initialSelected || 0]
  );

  useEffect(() => {
    setSelectedOption(props.options[props.initialSelected || 0]);
  }, [props.initialSelected]);

  useEffect(() => {
    if (props.disabled) {
      setSelectedOption(props.options[0]);
      props.onOptionChange(0);
    }
  }, [props.disabled]);

  const onSelect = (option: { value: string }) => {
    const index = props.options.indexOf(option.value);
    const actualValue = props.optionValues[index];
    props.onOptionChange(actualValue);
  };

  return (
    <div className="titledropdown__container">
      <div className="titledropdown__container__name">{props.name}</div>
      <div>
        <Dropdown
          options={props.options}
          onChange={onSelect}
          value={selectedOption}
          placeholder="Select an option"
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};

export default TitleDropdown;
